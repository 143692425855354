import { Box, Flex, Stack } from '@chakra-ui/react';
import React from 'react';
import { InterviewScorecardItem } from '../../../../helpers/InterviewScorecardItem';
import { Heading } from '../../../../../../helpers/Heading';
import { JobLocationHelper } from '../../../../helpers/JobLocationHelper';
import { ShareCandidateProfileJob } from '../../../../../candidateShares/components/ShareCandidateProfile';
import { renderJobTitle } from '../../../../utils/renderJobTitle';
import {
  JobJobTitle,
  JobId,
  JobName,
  JobNanoId,
  FetchJobEnabled
} from '../../../../jobsTypes';
import {
  fetchJobQuery,
  FetchJobResponse
} from '../../../../queries/fetchJob.query';
import { JobsCache } from '../../../../JobsCache';
import { useFetchJobByNanoId } from '../../../../hooks/useFetchJobByNanoId';

interface JobDetails {
  id: JobId;
  nanoId: JobNanoId;
  jobTitle: JobJobTitle;
  name: JobName;
}

interface JobProps {
  job: JobDetails;
}

function ViewJobCandidateInterviewScoreCardsPage({ job }: JobProps) {
  const { job: jobs } = useFetchJobByNanoId<FetchJobResponse>({
    query: fetchJobQuery,
    jobNanoId: job.nanoId as JobNanoId,
    enabled: !!job.nanoId as FetchJobEnabled,
    cacheKey: JobsCache.showCacheKey()
  });

  return (
    <Stack p={4} gap={4} flex={1} bg="white" borderRadius="base">
      <Flex>
        <Heading
          level={'h3'}
          color="gray.900"
          wordBreak="break-all"
          px={{ base: 4, lg: 3 }}
        >
          <Box as="span">Interview Scorecard </Box>
          <Box as="span" color="primary.500" pl={'10px'}>
            {renderJobTitle(job)}
          </Box>
        </Heading>

        <JobLocationHelper job={jobs as ShareCandidateProfileJob} withIcon />
      </Flex>
      <Flex
        gap={6}
        bg="white"
        height="100%"
        flexDir="column"
        px={{ base: 4, lg: 3 }}
      >
        <Stack spacing={6} flex={1}>
          {/* {scorecards.map((scorecard, index) => (
            <InterviewScorecardItem
              key={index}
              state="view"
              questions={scorecard.questions}
            />
          ))} */}
        </Stack>
      </Flex>
    </Stack>
  );
}

export default ViewJobCandidateInterviewScoreCardsPage;

export const scorecards = [
  {
    questions: [
      { question: 'Job fit / Relevant background?' },
      { question: 'Interpersonal / Communication' },
      { question: 'Professional Impression?' },
      { question: 'Motivation / Intuitive?' }
    ]
  }
];
