import { Icon, IconProps } from '@chakra-ui/icon';

const AlertIcon = ({
  w = '16px',
  h = '16px',
  fill = 'currentColor',
  ...props
}: IconProps) => (
  <Icon viewBox="0 0 16 16" w={w} h={h} fill={fill} {...props}>
    <path d="M8.866 2.1l5.756 9.969a1 1 0 01-.866 1.5H2.244a1 1 0 01-.866-1.5l5.756-9.97a1 1 0 011.732 0zM8 10a.667.667 0 100 1.335A.667.667 0 008 10zm0-4.666a.667.667 0 00-.662.589L7.333 6v2.667a.667.667 0 001.33.078l.004-.078V6A.667.667 0 008 5.334z" />
  </Icon>
);
export default AlertIcon;
