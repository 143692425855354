import {
  useReactHookForm,
  ReactHookFormFieldError
} from '../../../../../../../common/hooks/base/useReactHookForm';

import {
  CandidateSubmissionBaseSalary,
  CandidateSubmissionComment,
  CandidateSubmissionEvaluations,
  CandidateSubmissionFields,
  CandidateSubmissionOverallEvaluation,
  CandidateSubmissionShareSettings,
  CandidateSubmissionShowCandidatePreferredSalary,
  CandidateSubmissionVisibilityOptionType,
  CandidateSubmissionVisibilityOptionTypeEnum
} from '../../../../../candidateSubmissionsTypes';

import { useAddCandidateSubmissionFormValidationRules } from '../useAddCandidateSubmissionFormValidationRules';

import {
  OnAddCandidateSubmissionForm,
  AddCandidateSubmissionFormData
} from '../../AddCandidateSubmissionForm.types';

const defaultAddCandidateSubmissionValues: AddCandidateSubmissionFormData = {
  comment: '' as CandidateSubmissionComment,
  recipientIds: [],
  shareSettings: {} as CandidateSubmissionShareSettings,
  overallEvaluation: '' as CandidateSubmissionOverallEvaluation,
  evaluations: {} as CandidateSubmissionEvaluations,
  baseSalary: '' as CandidateSubmissionBaseSalary,
  showCandidatePreferredSalary:
    false as CandidateSubmissionShowCandidatePreferredSalary,
  visibilityOptionType:
    CandidateSubmissionVisibilityOptionTypeEnum.AllHiringTeam as CandidateSubmissionVisibilityOptionType
};

interface AddCandidateSubmissionFormOptions {
  defaultValues?: Partial<AddCandidateSubmissionFormData>;
  onAddCandidateSubmission: OnAddCandidateSubmissionForm;
}

function useAddCandidateSubmissionForm({
  defaultValues = {},
  onAddCandidateSubmission
}: AddCandidateSubmissionFormOptions) {
  const {
    control,
    watch,
    setValue,
    trigger,
    errors,
    isLoading,
    errorMessage,
    register,
    resetForm,
    handleSubmitReactHookForm
  } = useReactHookForm<AddCandidateSubmissionFormData>({
    defaultValues: { ...defaultAddCandidateSubmissionValues, ...defaultValues }
  });

  const {
    recipientIdsRules,
    commentRules,
    overallEvaluationRules,
    evaluationsRules
  } = useAddCandidateSubmissionFormValidationRules();

  return {
    control,
    watch,
    validationErrors: {
      recipientIdsValidationError: (
        errors.recipientIds as ReactHookFormFieldError
      )?.message,
      commentValidationError: (errors.comment as ReactHookFormFieldError)
        ?.message,
      overallEvaluationValidationError: (
        errors.overallEvaluation as ReactHookFormFieldError
      )?.message,
      evaluationsValidationError: (
        errors.evaluations as ReactHookFormFieldError
      )?.message,
      baseSalaryValidationError: (errors.baseSalary as ReactHookFormFieldError)
        ?.message,
      shareSettingsValidationError: (
        errors.shareSettings as ReactHookFormFieldError
      )?.message,
      showCandidatePreferredSalaryValidationError: (
        errors.showCandidatePreferredSalary as ReactHookFormFieldError
      )?.message,
      visibilityOptionTypeValidationError: (
        errors.visibilityOptionType as ReactHookFormFieldError
      )?.message
    },
    addCandidateSubmissionFormIsLoading: isLoading,
    addCandidateSubmissionFormErrorMessage: errorMessage,
    setCandidateSubmissionFormValue: setValue,
    triggerCandidateSubmissionForm: trigger,
    resetAddCandidateSubmissionForm: resetForm,
    registerFields: {
      registerComment: register(
        CandidateSubmissionFields.COMMENT,
        commentRules
      ),
      registerRecipientIds: register(
        CandidateSubmissionFields.RECIPIENT_IDS,
        recipientIdsRules
      ),
      registerShareSettings: register(CandidateSubmissionFields.SHARE_SETTINGS),
      registerBaseSalary: register(CandidateSubmissionFields.BASE_SALARY),
      registerOverallEvaluation: register(
        CandidateSubmissionFields.OVERALL_EVALUATION,
        overallEvaluationRules
      ),
      registerEvaluations: register(
        CandidateSubmissionFields.EVALUATIONS,
        evaluationsRules
      ),
      registerUseCandidateSalary: register(
        CandidateSubmissionFields.SHOW_CANDIDATE_PREFERRED_SALARY
      ),
      registerVisibilityOptionType: register(
        CandidateSubmissionFields.VISIBILITY_OPTION_TYPE
      )
    },
    handleAddCandidateSubmission: handleSubmitReactHookForm({
      onSubmit: (data) =>
        onAddCandidateSubmission?.({
          comment: data.comment as CandidateSubmissionComment,
          recipientIds: data.recipientIds,
          shareSettings: data.shareSettings as CandidateSubmissionShareSettings,
          evaluations: data.evaluations as CandidateSubmissionEvaluations,
          overallEvaluation:
            data.overallEvaluation as CandidateSubmissionOverallEvaluation,
          baseSalary: data.baseSalary as CandidateSubmissionBaseSalary,
          showCandidatePreferredSalary:
            data.showCandidatePreferredSalary as CandidateSubmissionShowCandidatePreferredSalary,
          visibilityOptionType:
            data.visibilityOptionType as CandidateSubmissionVisibilityOptionType
        })
    })
  };
}

export default useAddCandidateSubmissionForm;
