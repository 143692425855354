import {
  Box,
  Flex,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  useDisclosure
} from '@chakra-ui/react';
import { MouseEvent, ReactNode, useState } from 'react';
import { Button, ButtonProps } from '../../../../../../../../helpers/Button';
import { Text } from '../../../../../../../../helpers/Text';
import { ChevronDownIcon } from '../../../../../../../../icons/ChevronDownIcon';
import { WorkflowStageNanoId } from '../../../../../../../workflowStages/workflowStagesTypes';
import { JobCandidateDetailsPageWorkflowStage } from '../../../../ViewJobCandidateDetailsPage.types';

interface ViewJobCandidateDetailsStagesDropdownProps {
  workflowStages: JobCandidateDetailsPageWorkflowStage[];
  workflowStageNanoId?: WorkflowStageNanoId;
  onClick?: ButtonProps['onClick'];
  rightElement?: ReactNode;
}

export default function ViewJobCandidateDetailsStagesDropdown({
  onClick,
  rightElement,
  workflowStages,
  workflowStageNanoId
}: ViewJobCandidateDetailsStagesDropdownProps) {
  const [selectedStage, setSelectedStage] = useState(workflowStageNanoId);
  const { isOpen, onToggle, onClose } = useDisclosure();

  const changeStage = (e: MouseEvent<HTMLButtonElement>) => {
    const { value } = e.currentTarget;

    setSelectedStage(value as WorkflowStageNanoId);

    !!onClick && onClick(e);

    onClose();
  };

  const selectedStageName = workflowStages.find(
    (stage) => stage.nanoId === selectedStage
  )?.name;

  return (
    <Popover isOpen={isOpen} onClose={onClose} isLazy>
      <PopoverTrigger>
        <Flex alignItems="stretch" w="full">
          <Button
            p={0}
            w="full"
            h="100%"
            minH={6}
            minW={0}
            size="small"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            wordBreak="break-all"
            onClick={(e) => {
              e.stopPropagation();
              onToggle();
            }}
            border="none"
            textAlign="left"
            boxShadow="none"
            fontSize="inherit"
            hierarchy="unstyled"
            aria-label="Dropdown"
          >
            <Flex
              w="full"
              gap={1}
              whiteSpace="nowrap"
              wordBreak="break-all"
              alignItems="center"
              fontSize="inherit"
            >
              <Text
                noOfLines={1}
                whiteSpace="nowrap"
                fontWeight="medium"
                wordBreak="break-all"
                textOverflow="ellipsis"
                textTransform="capitalize"
              >
                {selectedStageName}
              </Text>

              {rightElement}
              <ChevronDownIcon ml="auto" fill="gray.500" />
            </Flex>
          </Button>
        </Flex>
      </PopoverTrigger>

      <Portal>
        <PopoverContent p={1} w={48} overflow="hidden">
          <Flex flexDir="column" overflow="auto">
            {workflowStages.map((stage) => (
              <Button
                px={2}
                py={1}
                gap={2}
                h="auto"
                size="small"
                display="flex"
                color="inherit"
                textAlign="left"
                lineHeight={1.5}
                fontSize="inherit"
                borderRadius="none"
                fontWeight="inherit"
                hierarchy="unstyled"
                textTransform="capitalize"
                justifyContent="flex-start"
                isDisabled={selectedStage === stage.nanoId}
                onClick={(e) => {
                  e.stopPropagation();
                  changeStage(e);
                }}
                value={stage.nanoId}
                key={stage.nanoId}
                _hover={{
                  bg: 'gray.100'
                }}
              >
                <Box w={2.5} h={2.5} bg={stage.color} rounded="full" />

                {stage.name}
              </Button>
            ))}
          </Flex>
        </PopoverContent>
      </Portal>
    </Popover>
  );
}
