import { Flex, HStack, StackDivider, useDisclosure } from '@chakra-ui/react';
import startCase from 'lodash/startCase';
import { MouseEvent } from 'react';

import { useChakraToast } from '../../../../../../helpers/useChakraToast';
import {
  JobCandidateFirstName,
  JobCandidateName,
  JobCandidateNanoId
} from '../../../../../jobCandidates/jobCandidatesTypes';
import { WorkflowStagesCache } from '../../../../../workflowStages/WorkflowStagesCache';
import { usePaginatedWorkflowStages } from '../../../../../workflowStages/hooks/usePaginatedWorkflowStages';
import {
  FetchWorkflowStagesResponse,
  fetchWorkflowStagesQuery
} from '../../../../../workflowStages/queries/fetchWorkflowStages.query';
import {
  FetchWorkflowStagesFilters,
  FetchWorkflowStagesSort,
  WorkflowStageFields
} from '../../../../../workflowStages/workflowStagesTypes';
import { JobId, JobJobTitle, JobName, JobNanoId } from '../../../../jobsTypes';
import {
  JobCandidateDetailsJobCandidate,
  JobCandidateDetailsPageWorkflowStage
} from '../../ViewJobCandidateDetailsPage.types';
import { ViewJobCandidateDetailsTasksWidgetProvider } from '../../contexts/ViewJobCandidateDetailsTasksWidgetContext';
import { ViewJobCandidateDetailsStageSection } from './components/ViewJobCandidateDetailsStageSection';
import { ViewJobCandidateDetailsTaskRequireAttention } from './components/ViewJobCandidateDetailsTaskRequireAttention';
import { ViewJobCandidateDetailsTasksSection } from './components/ViewJobCandidateDetailsTasksSection';
import { ViewJobCandidateInterviewScoreCardModal } from './components/ViewJobCandidateInterviewScoreCardModal';

interface ViewJobCandidateDetailsStageTasksManagerProps {
  jobCandidateNanoId: JobCandidateNanoId;
  requiresCredit?: boolean;
  job: {
    id: JobId;
    nanoId: JobNanoId;
    jobTitle: JobJobTitle;
    name: JobName;
  };
  jobCandidate: {
    name: JobCandidateName;
    nanoId: JobCandidateNanoId;
    firstName?: JobCandidateFirstName;
    workflowStage: JobCandidateDetailsPageWorkflowStage;
  };
  jobCandidateDetails?: JobCandidateDetailsJobCandidate;
}

export default function ViewJobCandidateDetailsStageTasksManager({
  job,
  jobCandidate,
  jobCandidateNanoId,
  requiresCredit,
  jobCandidateDetails
}: ViewJobCandidateDetailsStageTasksManagerProps) {
  const toast = useChakraToast();

  const {
    isOpen: isOpenScoreCard,
    onOpen: openScoreCard,
    onClose: closeScoreCard
  } = useDisclosure();

  const { workflowStages } =
    usePaginatedWorkflowStages<FetchWorkflowStagesResponse>({
      query: fetchWorkflowStagesQuery,
      cacheKey: WorkflowStagesCache.jobIndexCacheKey(job.nanoId),
      initialFilters: {
        [WorkflowStageFields.JOB_ID]: { operator: 'eq', value: job.id }
      } as unknown as FetchWorkflowStagesFilters,
      initialSort: {
        [WorkflowStageFields.ARRANGEMENT]: { ascending: true }
      } as unknown as FetchWorkflowStagesSort
    });

  const changeWorkflowStage = (e: MouseEvent<HTMLButtonElement>) => {
    const selectedStage = workflowStages.find(
      (stage) => stage.nanoId === e.currentTarget.value
    );

    if (selectedStage?.name === 'Interview') {
      openScoreCard();
    }

    toast({
      title: `${startCase(selectedStage?.name)} stage selected`,
      status: 'success',
      position: 'top-right',
      duration: 2000,
      isClosable: true
    });
  };

  const { isOpen: isOpenSkipTask, onOpen: onOpenSkipTask } = useDisclosure();

  return (
    <Flex>
      {isOpenSkipTask ? (
        <HStack
          spacing={0}
          bg="gray.50"
          border="1px solid"
          borderColor="gray.200"
          divider={<StackDivider />}
          borderRadius="base"
          justifyContent="flex-start"
        >
          <ViewJobCandidateDetailsStageSection
            jobCandidate={jobCandidate}
            workflowStages={workflowStages}
            changeWorkflowStage={changeWorkflowStage}
          />

          <ViewJobCandidateDetailsTasksWidgetProvider
            jobNanoId={job.nanoId}
            jobCandidate={jobCandidate}
            jobCandidateNanoId={jobCandidateNanoId}
          >
            <ViewJobCandidateDetailsTasksSection />
          </ViewJobCandidateDetailsTasksWidgetProvider>
        </HStack>
      ) : (
        <ViewJobCandidateDetailsTaskRequireAttention
          jobCandidate={jobCandidate}
          skipTask={onOpenSkipTask}
          status="overdue"
        />
      )}

      <ViewJobCandidateInterviewScoreCardModal
        jobCandidateNanoId={jobCandidateNanoId}
        requiresCredit={requiresCredit}
        jobCandidateDetails={jobCandidateDetails}
        isOpenScoreCard={isOpenScoreCard}
        closeScoreCard={closeScoreCard}
        job={job}
      />
    </Flex>
  );
}
