import React from 'react';
import ScheduleHelper from '../../ScheduleHelper';
import { Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/react';
import { JobCandidateNanoId } from '../../../../main/jobCandidates/jobCandidatesTypes';
import { JobNanoId } from '../../../../main/jobs/jobsTypes';

interface ScheduleHelperModalProps {
  title?: string;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  jobNanoId?: JobNanoId;
  jobCandidateNanoId?: JobCandidateNanoId;
}

export default function ScheduleHelperModal({
  title,
  isOpen,
  onClose,
  jobNanoId,
  jobCandidateNanoId
}: ScheduleHelperModalProps) {
  return (
    <Modal
      size="5xl"
      isOpen={isOpen}
      onClose={onClose}
      closeOnEsc={false}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />

      <ModalContent>
        <ModalBody bg="green.300" p={0}>
          <ScheduleHelper
            title={title}
            onCancel={onClose}
            jobNanoId={jobNanoId}
            jobCandidateNanoId={jobCandidateNanoId}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
