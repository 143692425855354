import { useDisclosure } from '@chakra-ui/react';
import first from 'lodash/first';
import reduce from 'lodash/reduce';
import size from 'lodash/size';
import uniqBy from 'lodash/uniqBy';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import { useCurrentUser } from '../../../../../../auth/hooks/useAuth';
import { useChakraToast } from '../../../../../../helpers/useChakraToast';
import { CandidateSubmissionJobCandidatesCache } from '../../../../../candidateSubmissionJobCandidates/CandidateSubmissionJobCandidatesCache';
import {
  CandidateSubmissionJobCandidateFields,
  FetchCandidateSubmissionJobCandidatesEnabled,
  FetchCandidateSubmissionJobCandidatesFilters
} from '../../../../../candidateSubmissionJobCandidates/candidateSubmissionJobCandidatesTypes';
import { usePaginatedCandidateSubmissionJobCandidates } from '../../../../../candidateSubmissionJobCandidates/hooks/usePaginatedCandidateSubmissionJobCandidates';
import {
  fetchCandidateSubmissionJobCandidatesQuery,
  FetchCandidateSubmissionJobCandidatesResponse
} from '../../../../../candidateSubmissionJobCandidates/queries/fetchCandidateSubmissionJobCandidates.query';
import { CreateCandidateSubmissionRequestData } from '../../../../../candidateSubmissions/CandidateSubmissionsBffRequests';
import { CandidateSubmissionsCache } from '../../../../../candidateSubmissions/CandidateSubmissionsCache';
import {
  CandidateSubmissionBaseSalary,
  CandidateSubmissionComment,
  CandidateSubmissionEvaluation,
  CandidateSubmissionEvaluations,
  CandidateSubmissionEvaluationsEnum,
  CandidateSubmissionFields,
  CandidateSubmissionOverallEvaluation,
  CandidateSubmissionRecipients,
  CandidateSubmissionShareSettings,
  CandidateSubmissionShareSettingsField,
  CandidateSubmissionShareSettingsFields,
  CandidateSubmissionShowCandidatePreferredSalary,
  CandidateSubmissionVisibilityOptionType,
  CandidateSubmissionVisibilityOptionTypeEnum,
  FetchCandidateSubmissionsCacheKey
} from '../../../../../candidateSubmissions/candidateSubmissionsTypes';
import { useCreateCandidateSubmission } from '../../../../../candidateSubmissions/hooks/useCreateCandidateSubmission';
import { useSendEmailsCandidateSubmission } from '../../../../../candidateSubmissions/hooks/useSendEmailsCandidateSubmission';
import { useFetchJobCandidateByNanoId } from '../../../../../jobCandidates/hooks/useFetchJobCandidateByNanoId';
import { JobCandidatesCache } from '../../../../../jobCandidates/JobCandidatesCache';
import {
  FetchJobCandidateEnabled,
  JobCandidateNanoId
} from '../../../../../jobCandidates/jobCandidatesTypes';
import {
  fetchJobCandidateQuery,
  FetchJobCandidateResponse
} from '../../../../../jobCandidates/queries/fetchJobCandidate.query';
import { WorkflowStagesCache } from '../../../../../workflowStages/WorkflowStagesCache';
import { useFetchJobByNanoId } from '../../../../hooks/useFetchJobByNanoId';
import { JobsCache } from '../../../../JobsCache';
import { FetchJobEnabled } from '../../../../jobsTypes';
import {
  fetchJobQuery,
  FetchJobResponse
} from '../../../../queries/fetchJob.query';
import { CustomiseCandidateSubmissionNav } from '../../components/ViewJobCandidateDetailsSubmissionSidebar/components/CustomiseCandidateSubmissionNav';
import {
  ViewJobCandidateDetailsSubmissionContextType,
  ViewJobCandidateDetailsSubmissionProviderProps
} from './ViewJobCandidateDetailsSubmissionContext.types';
import { RecipientId } from '../../../../../recipients/recipientsTypes';
import { useAddCandidateSubmissionForm } from '../../../../../candidateSubmissions/components/forms/AddCandidateSubmissionForm/hooks/useAddCandidateSubmissionForm';
import { AddCandidateSubmissionFormData } from '../../../../../candidateSubmissions/components/forms/AddCandidateSubmissionForm/AddCandidateSubmissionForm.types';
import { Control } from 'react-hook-form';

const ViewJobCandidateDetailsSubmissionContext =
  createContext<ViewJobCandidateDetailsSubmissionContextType>({
    totalSteps: 4,
    currentStep: 1,
    shareSettings: [],
    sharedFields: [],
    handleShareField: function (): void {
      /*  */
    },
    isLastStep: false,
    isFirstStep: false,
    isCandidateSubmissionOpen: false,
    overallEvaluation: '' as CandidateSubmissionOverallEvaluation,
    comment: '' as CandidateSubmissionComment,
    evaluations: {} as CandidateSubmissionEvaluations,
    visibilityOptionType:
      CandidateSubmissionVisibilityOptionTypeEnum.AllHiringTeam as CandidateSubmissionVisibilityOptionType,
    recipients: [] as unknown as CandidateSubmissionRecipients,
    baseSalary: '' as CandidateSubmissionBaseSalary,
    errorMessage: '',
    showCandidatePreferredSalary: false,
    validationErrors: {},
    registerFields:
      {} as ViewJobCandidateDetailsSubmissionContextType['registerFields'],
    setCandidateSubmissionFormValue: function (): void {
      /* */
    },
    control: {} as Control<AddCandidateSubmissionFormData>,
    updateEvaluations: () => {
      /*  */
    },
    openCandidateSubmission: function (): void {
      /*  */
    },
    closeCandidateSubmission: function (): void {
      /*  */
    },
    goToPrevStep: function (): void {
      /*  */
    },
    goToNextStep: function (): void {
      /*  */
    },
    sendSubmission: function (): void {
      /*  */
    }
  });

export function ViewJobCandidateDetailsSubmissionProvider({
  children,
  jobNanoId,
  jobCandidateNanoId,
  isSubmitProfileOpen,
  onRemoveOpenSubmitFromQuery,
  isHiringPortalPath
}: ViewJobCandidateDetailsSubmissionProviderProps) {
  const toast = useChakraToast();
  const currentUser = useCurrentUser();
  const { job } = useFetchJobByNanoId<FetchJobResponse>({
    query: fetchJobQuery,
    jobNanoId,
    enabled: !!jobNanoId as FetchJobEnabled,
    cacheKey: JobsCache.showCacheKey()
  });
  const { jobCandidate } =
    useFetchJobCandidateByNanoId<FetchJobCandidateResponse>({
      jobCandidateNanoId: jobCandidateNanoId as JobCandidateNanoId,
      enabled: !!jobCandidateNanoId as FetchJobCandidateEnabled,
      cacheKey: JobCandidatesCache.showCacheKey(),
      query: fetchJobCandidateQuery
    });

  const { candidateSubmissionJobCandidates } =
    usePaginatedCandidateSubmissionJobCandidates<FetchCandidateSubmissionJobCandidatesResponse>(
      {
        query: fetchCandidateSubmissionJobCandidatesQuery,
        cacheKey:
          CandidateSubmissionJobCandidatesCache.jobCandidateIndexCacheKey(
            jobCandidateNanoId
          ),
        enabled:
          isHiringPortalPath as FetchCandidateSubmissionJobCandidatesEnabled,
        initialFilters: {
          [CandidateSubmissionJobCandidateFields.JOB_CANDIDATE_ID]: {
            operator: 'eq',
            value: jobCandidate?.id
          },
          [CandidateSubmissionJobCandidateFields.RECIPIENT_IDS]: {
            operator: 'eq',
            value: [currentUser.id]
          }
        } as unknown as FetchCandidateSubmissionJobCandidatesFilters
      }
    );

  const {
    createCandidateSubmission,
    createCandidateSubmissionErrorMessage,
    createCandidateSubmissionIsLoading
  } = useCreateCandidateSubmission({
    cacheKeys: [
      CandidateSubmissionsCache.indexCacheKey(),
      JobCandidatesCache.jobIndexCacheKey(
        jobNanoId
      ) as unknown as FetchCandidateSubmissionsCacheKey,
      WorkflowStagesCache.jobIndexCacheKey(
        jobNanoId
      ) as unknown as FetchCandidateSubmissionsCacheKey
    ]
  });

  const {
    sendEmailsCandidateSubmission,
    sendEmailsCandidateSubmissionErrorMessage,
    sendEmailsCandidateSubmissionIsLoading
  } = useSendEmailsCandidateSubmission({});

  const defaultSubmissionFields = Object.values(
    CandidateSubmissionShareSettingsFields
  ) as CandidateSubmissionShareSettingsField[];

  const defaultEvaluations: CandidateSubmissionEvaluations = {
    [CandidateSubmissionEvaluationsEnum.JobFit]: {
      rating: 0,
      comment: ''
    },
    [CandidateSubmissionEvaluationsEnum.Communication]: {
      rating: 0,
      comment: ''
    },
    [CandidateSubmissionEvaluationsEnum.Impression]: {
      rating: 0,
      comment: ''
    },
    [CandidateSubmissionEvaluationsEnum.Motivation]: {
      rating: 0,
      comment: ''
    },
    [CandidateSubmissionEvaluationsEnum.Overall]: {
      rating: 0,
      comment: ''
    }
  };

  const totalSteps = 4;
  const [currentStep, setCurrentStep] = useState(1);

  const {
    watch,
    control,
    registerFields,
    validationErrors,
    triggerCandidateSubmissionForm,
    setCandidateSubmissionFormValue,
    handleAddCandidateSubmission
  } = useAddCandidateSubmissionForm({
    defaultValues: {
      comment: '' as CandidateSubmissionComment,
      recipientIds: [] as RecipientId[],
      evaluations: defaultEvaluations,
      visibilityOptionType:
        CandidateSubmissionVisibilityOptionTypeEnum.AllHiringTeam as CandidateSubmissionVisibilityOptionType,
      shareSettings: reduce(
        defaultSubmissionFields,
        (acc, field) => ({ ...acc, [field]: true }),
        {}
      ) as CandidateSubmissionShareSettings,
      overallEvaluation: '' as CandidateSubmissionOverallEvaluation,
      baseSalary: '' as CandidateSubmissionBaseSalary,
      showCandidatePreferredSalary:
        false as CandidateSubmissionShowCandidatePreferredSalary
    },
    onAddCandidateSubmission: async (data) => {
      const visibilityOptionType = data.visibilityOptionType;
      const { hiringManager, interviewers, recruiter, recruitingCoordinator } =
        job || {};

      const allHiringTeamRecipients =
        visibilityOptionType ===
        CandidateSubmissionVisibilityOptionTypeEnum.AllHiringTeam
          ? [
              hiringManager,
              ...(interviewers || []),
              recruiter,
              recruitingCoordinator
            ]
          : undefined;

      const recruiterRecipients =
        visibilityOptionType ===
        CandidateSubmissionVisibilityOptionTypeEnum.AllHiringTeam
          ? [hiringManager, recruiter, recruitingCoordinator]
          : undefined;

      const hiringManagerRecipient =
        visibilityOptionType ===
        CandidateSubmissionVisibilityOptionTypeEnum.HiringManagers
          ? [hiringManager]
          : undefined;

      const recipients = (
        allHiringTeamRecipients ||
        recruiterRecipients ||
        hiringManagerRecipient
      )?.filter((val) =>
        Boolean(val)
      ) as unknown as CandidateSubmissionRecipients;

      const uniqRecipients = uniqBy(
        recipients,
        'id'
      ) as CandidateSubmissionRecipients;

      const recipientIds =
        size(data.recipientIds) > 0
          ? data.recipientIds
          : uniqRecipients.map(({ id }) => id);

      const payload = {
        recipientIds,
        shareSettings: data.shareSettings,
        jobId: job?.id,
        jobCandidateIds: [jobCandidate?.id],
        comment: data.comment,
        overallEvaluation: data.overallEvaluation,
        evaluations: data.evaluations,
        baseSalary: data.baseSalary,
        showCandidatePreferredSalary: data.showCandidatePreferredSalary
      };

      const response = await createCandidateSubmission(
        payload as unknown as CreateCandidateSubmissionRequestData
      );

      if (response.id) {
        await sendEmailsCandidateSubmission({
          candidateSubmissionId: response.id
        });
        closeCandidateSubmission();
        toast({
          title: 'Candidate submission sent',
          status: 'success'
        });
      }
    }
  });

  const {
    onOpen: openCandidateSubmission,
    onClose: closeCandidateSubmission,
    isOpen: isCandidateSubmissionOpen
  } = useDisclosure({
    onClose: () => {
      setCurrentStep(1);
      if (isSubmitProfileOpen) {
        onRemoveOpenSubmitFromQuery?.();
      }
    },
    defaultIsOpen: isSubmitProfileOpen
  });

  const goToNextStep = useCallback(async () => {
    if (currentStep === totalSteps) return;

    if (currentStep === 1) {
      const isValid = await triggerCandidateSubmissionForm([
        CandidateSubmissionFields.BASE_SALARY
      ]);
      if (!isValid) {
        return;
      }
    }

    if (currentStep === 3) {
      const isValid = await triggerCandidateSubmissionForm([
        CandidateSubmissionFields.EVALUATIONS,
        CandidateSubmissionFields.OVERALL_EVALUATION,
        CandidateSubmissionFields.COMMENT
      ]);
      if (!isValid) {
        return;
      }
    }

    setCurrentStep(currentStep + 1);
  }, [currentStep, triggerCandidateSubmissionForm]);

  const goToPrevStep = useCallback(() => {
    if (currentStep === 1) return;

    setCurrentStep(currentStep - 1);
  }, [currentStep]);

  const {
    baseSalary,
    comment,
    evaluations,
    overallEvaluation,
    shareSettings: sharedFields,
    showCandidatePreferredSalary,
    visibilityOptionType
  } = watch();

  const updateEvaluations = useCallback<
    (evaluation: CandidateSubmissionEvaluation) => void
  >(
    (evaluation) => {
      const { evaluationType, ...rest } = evaluation;
      setCandidateSubmissionFormValue(CandidateSubmissionFields.EVALUATIONS, {
        ...evaluations,
        [evaluationType]: rest
      } as never);
    },
    [evaluations, setCandidateSubmissionFormValue]
  );

  const { hiringManager, interviewers, recruiter, recruitingCoordinator } =
    job || {};

  const allHiringTeamRecipients =
    visibilityOptionType ===
    CandidateSubmissionVisibilityOptionTypeEnum.AllHiringTeam
      ? [
          hiringManager,
          ...(interviewers || []),
          recruiter,
          recruitingCoordinator
        ]
      : undefined;

  const recruiterRecipients =
    visibilityOptionType ===
    CandidateSubmissionVisibilityOptionTypeEnum.AllHiringTeam
      ? [hiringManager, recruiter, recruitingCoordinator]
      : undefined;

  const hiringManagerRecipient =
    visibilityOptionType ===
    CandidateSubmissionVisibilityOptionTypeEnum.HiringManagers
      ? [hiringManager]
      : undefined;

  const recipients = (
    allHiringTeamRecipients ||
    recruiterRecipients ||
    hiringManagerRecipient
  )?.filter((val) => Boolean(val)) as unknown as CandidateSubmissionRecipients;

  const uniqRecipients = uniqBy(
    recipients,
    'id'
  ) as CandidateSubmissionRecipients;

  const sendSubmission = () => {
    handleAddCandidateSubmission();
  };

  const handleShareField = useCallback(
    (field: CandidateSubmissionShareSettingsField) => {
      const _shareFields = Object.keys(sharedFields).filter(
        (key) => sharedFields[key as keyof typeof sharedFields]
      ) as CandidateSubmissionShareSettingsField[];
      let newShareFields = _shareFields;
      if (_shareFields.includes(field)) {
        const updatedFields = _shareFields.filter((f) => f !== field);

        if (
          field ===
            CandidateSubmissionShareSettingsFields.ProfileCandidateName &&
          !updatedFields.includes(
            CandidateSubmissionShareSettingsFields.ProfileJobTitle as CandidateSubmissionShareSettingsField
          )
        ) {
          updatedFields.push(
            CandidateSubmissionShareSettingsFields.ProfileJobTitle as CandidateSubmissionShareSettingsField
          );
        }

        newShareFields = updatedFields;
      } else {
        newShareFields = [..._shareFields, field];
      }

      const latestShareSettings = reduce(
        newShareFields,
        (acc, field) => ({ ...acc, [field]: true }),
        {}
      ) as CandidateSubmissionShareSettings;

      setCandidateSubmissionFormValue(
        CandidateSubmissionFields.SHARE_SETTINGS,
        latestShareSettings as never
      );
    },
    [sharedFields, setCandidateSubmissionFormValue]
  );

  const candidateSubmissionShareSettings = first(
    candidateSubmissionJobCandidates
  )?.candidateSubmission?.shareSettings;

  const shareSettingsArr = candidateSubmissionShareSettings
    ? (Object.keys(candidateSubmissionShareSettings).filter(
        (key) =>
          candidateSubmissionShareSettings[
            key as keyof typeof candidateSubmissionShareSettings
          ]
      ) as CandidateSubmissionShareSettingsField[])
    : [];

  const _shareFields = Object.keys(sharedFields).filter(
    (key) => sharedFields[key as keyof typeof sharedFields]
  ) as CandidateSubmissionShareSettingsField[];

  const shareSettings = isHiringPortalPath ? shareSettingsArr : _shareFields;

  useEffect(() => {
    if (baseSalary && sharedFields.assessmentPreferencesSalary) {
      handleShareField(
        CandidateSubmissionShareSettingsFields.AssessmentPreferencesSalary as CandidateSubmissionShareSettingsField
      );
    }
  }, [baseSalary, handleShareField, sharedFields]);

  const values = {
    totalSteps,
    currentStep,
    goToNextStep,
    goToPrevStep,
    sendSubmission,
    shareSettings,
    sharedFields: _shareFields,
    handleShareField,
    openCandidateSubmission,
    closeCandidateSubmission,
    isCandidateSubmissionOpen,
    isFirstStep: currentStep === 1,
    isLastStep: currentStep === totalSteps,
    comment,
    overallEvaluation,
    setCandidateSubmissionFormValue,
    control,
    evaluations,
    updateEvaluations,
    visibilityOptionType,
    recipients: uniqRecipients,
    baseSalary,
    errorMessage:
      createCandidateSubmissionErrorMessage ||
      sendEmailsCandidateSubmissionErrorMessage,
    showCandidatePreferredSalary,
    validationErrors,
    registerFields
  };

  return (
    <ViewJobCandidateDetailsSubmissionContext.Provider value={values}>
      {children}

      {values.isCandidateSubmissionOpen && (
        <CustomiseCandidateSubmissionNav
          isLoading={
            sendEmailsCandidateSubmissionIsLoading ||
            createCandidateSubmissionIsLoading
          }
        />
      )}
    </ViewJobCandidateDetailsSubmissionContext.Provider>
  );
}

export const useViewJobCandidateDetailsSubmissionContext = () =>
  useContext(ViewJobCandidateDetailsSubmissionContext);
