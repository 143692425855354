import { Flex, HStack, Stack, useDisclosure } from '@chakra-ui/react';
import { Text } from '../../../../../../../../../../helpers/Text';
import { calendarEvents } from '../../calendarEvents';
import { ViewJobCandidateDetailsProfileCalendarEventItem } from '../ViewJobCandidateDetailsProfileCalendarEventItem';
import {
  DateUtils,
  DateTimeFormats
} from '../../../../../../../../../../utils/DateUtils';
import { Button } from '../../../../../../../../../../helpers/Button';
import { ScheduleHelperModal } from '../../../../../../../../../../helpers/ScheduleHelper/modal/ScheduleHelperModal';
import { PlusIcon } from '../../../../../../../../../../icons/PlusIcon';

interface ViewJobCandidateDetailsProfileCalendarEventsProps {
  selectedDay: string;
}

export default function ViewJobCandidateDetailsProfileCalendarEvents({
  selectedDay
}: ViewJobCandidateDetailsProfileCalendarEventsProps) {
  const selectedDayKey = DateUtils.formatDate(
    selectedDay,
    DateTimeFormats.YYYY_LL_DD
  ) as string;
  const eventsForSelectedDay = calendarEvents[selectedDayKey] || [];

  const {
    isOpen: isOpenAvailability,
    onOpen: onOpenAvailability,
    onClose: onCloseAvailability
  } = useDisclosure();

  return (
    <>
      <Stack spacing={3}>
        <Stack spacing={3}>
          <HStack>
            <Text textStyle="body1Medium">
              {DateUtils.formatDate(selectedDay, DateTimeFormats.MMM_DD)}
            </Text>
            <Text color="gray.600">
              {DateUtils.formatDate(selectedDay, DateTimeFormats.CCCC)}
            </Text>
          </HStack>

          {eventsForSelectedDay.map((event) => (
            <ViewJobCandidateDetailsProfileCalendarEventItem
              key={event.id}
              event={event}
            />
          ))}

          <Flex>
            {eventsForSelectedDay.length === 0 && (
              <Text color="gray.500">No events today</Text>
            )}

            <Button
              ml="auto"
              size="small"
              hierarchy="link"
              onClick={onOpenAvailability}
              gap={1}
            >
              <PlusIcon w={3} h={3} />
              <Flex as="span">Add availability</Flex>
            </Button>
          </Flex>
        </Stack>
      </Stack>

      <ScheduleHelperModal
        onSubmit={console.log}
        isOpen={isOpenAvailability}
        onClose={onCloseAvailability}
      />
    </>
  );
}
