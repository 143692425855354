import { ReactNode } from 'react';
import { Controller, FieldValues } from 'react-hook-form';
import { MenuPlacement } from 'chakra-react-select';
import { SystemProps, SystemStyleObject } from '@chakra-ui/react';

import {
  SelectFieldControl,
  SelectFieldControlOnInputChange,
  SelectOptions,
  SelectOptionType,
  SelectOptionWithImageType
} from './components/SelectFieldControl';

import {
  SelectFieldReactHookFormControl,
  SelectFieldReactHookFormFieldPath,
  SelectFieldReactHookFormRules
} from './SelectField.types';
import { ButtonHierarchyType } from '../../../Button';

interface SelectFieldProps<T extends FieldValues> {
  control: SelectFieldReactHookFormControl<T>;
  name: SelectFieldReactHookFormFieldPath<T>;
  rules?: SelectFieldReactHookFormRules<T>;
  label?: string;
  labelAddon?: ReactNode;
  placeholder?: ReactNode;
  i18nPlaceholder?: string;
  placeholderColor?: string;
  errorMessage?: string | null;
  isDisabled?: boolean;
  isLoading?: boolean;
  isInvalid?: boolean;
  isSearchable?: boolean;
  withImages?: boolean;
  withAddNewButton?: boolean;
  addNewButtonLabel?: string;
  addNewButtonHierarchy?: ButtonHierarchyType;
  addNewButtonDisabled?: boolean;
  addNewButtonLoading?: boolean;
  addNewButtonWithoutPlusIcon?: boolean;
  addNewButtonAction?: () => void;
  addNewButtonToValueAction?: () => Promise<SelectOptionType | undefined>;
  options: SelectOptions;
  defaultValue?: SelectOptionType | SelectOptionWithImageType;
  isRequired?: boolean;
  onInputChange?: SelectFieldControlOnInputChange;
  autoFocus?: boolean;
  menuPlacement?: MenuPlacement;
  w?: number;
  visibility?: SystemProps['visibility'];
  reactSelectStyles?: Record<string, unknown>;
  showDropdownIndicator?: boolean;
  unsetValue?: boolean;
  id?: string;
  onChangeValue?: (value: string | number | null) => void;
}

function SelectField<T extends FieldValues>({
  id,
  name,
  rules,
  label,
  control,
  labelAddon,
  placeholder,
  i18nPlaceholder,
  isDisabled,
  isSearchable,
  isLoading,
  isInvalid,
  withImages,
  options,
  defaultValue,
  errorMessage,
  isRequired,
  onInputChange,
  withAddNewButton,
  addNewButtonLabel,
  addNewButtonHierarchy,
  addNewButtonWithoutPlusIcon,
  addNewButtonDisabled,
  addNewButtonLoading,
  addNewButtonAction,
  addNewButtonToValueAction,
  autoFocus,
  menuPlacement,
  w,
  visibility,
  reactSelectStyles,
  showDropdownIndicator,
  unsetValue,
  onChangeValue
}: SelectFieldProps<T>) {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { onChange, value, ref } }) => (
        <SelectFieldControl
          name={name}
          ref={ref}
          id={id}
          label={label}
          labelAddon={labelAddon}
          placeholder={placeholder}
          i18nPlaceholder={i18nPlaceholder}
          value={value}
          isDisabled={isDisabled}
          isLoading={isLoading}
          hasError={isInvalid}
          withImages={withImages}
          withAddNewButton={withAddNewButton}
          addNewButtonLabel={addNewButtonLabel}
          addNewButtonHierarchy={addNewButtonHierarchy}
          addNewButtonDisabled={addNewButtonDisabled}
          addNewButtonLoading={addNewButtonLoading}
          addNewButtonAction={addNewButtonAction}
          addNewButtonToValueAction={addNewButtonToValueAction}
          addNewButtonWithoutPlusIcon={addNewButtonWithoutPlusIcon}
          options={options}
          defaultValue={defaultValue}
          unsetValue={unsetValue}
          isRequired={isRequired}
          isSearchable={isSearchable}
          errorMessage={errorMessage}
          onInputChange={onInputChange}
          onChange={(value) => {
            onChange(value);
            onChangeValue?.(value);
          }}
          autoFocus={!!autoFocus}
          menuPlacement={menuPlacement}
          w={w}
          visibility={visibility}
          reactSelectStyles={{
            ...(reactSelectStyles || {}),
            menu: (base: SystemStyleObject) => ({
              ...base,
              zIndex: 9999,
              width: 'max-content',
              minWidth: '100%',
              maxWidth: '500px'
            })
          }}
          showDropdownIndicator={showDropdownIndicator}
        />
      )}
    />
  );
}

export default SelectField;
