import {
  Flex,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  StackDivider
} from '@chakra-ui/react';
import { Heading } from '../../../../../../helpers/Heading';
import { NextLinkHelper } from '../../../../../../helpers/NextLinkHelper';
import { RelativeDateHelper } from '../../../../../../helpers/RelativeDateHelper';
import { Text } from '../../../../../../helpers/Text';
import { PureButtonWithRefHelper } from '../../../../../../helpers/buttons/PureButtonWithRefHelper';
import { ClockIcon } from '../../../../../../icons/ClockIcon';
import { JobsIcon } from '../../../../../../icons/JobsIcon';
import { JobPriorityDropdown } from '../../../../../updates/components/JobPriorityDropdown';
import { JobsRoutes } from '../../../../JobsRoutes';
import { JobLocationHelper } from '../../../../helpers/JobLocationHelper';
import { renderJobEmployment } from '../../../../utils/renderJobEmployment';
import { renderJobTitle } from '../../../../utils/renderJobTitle';
import { ViewJobCandidateDetailsJobHiringTeam } from '../ViewJobCandidateDetailsJobHiringTeam';
import { DeleteIcon } from '../../../../../../icons/DeleteIcon';
import { ViewJobCandidateDetailsJobDetailsJob } from './ViewJobCandidateDetailsJobDetails.types';
import { ChevronDownIcon } from '../../../../../../icons/ChevronDownIcon';

interface ViewJobCandidateDetailsJobDetailsProps {
  job: ViewJobCandidateDetailsJobDetailsJob;
  jobs?: ViewJobCandidateDetailsJobDetailsJob[];
}

export default function ViewJobCandidateDetailsJobDetails({
  job,
  jobs
}: ViewJobCandidateDetailsJobDetailsProps) {
  return (
    <HStack spacing={4} divider={<StackDivider />} flexWrap="wrap">
      <Menu isLazy>
        <MenuButton
          sx={{
            span: {
              display: 'flex',
              gap: '4px',
              alignItems: 'center'
            }
          }}
        >
          <Heading
            level="h2"
            fontSize="md"
            fontWeight="semibold"
            lineHeight={1.5}
          >
            {renderJobTitle(job)}
          </Heading>

          {jobs?.length ? <ChevronDownIcon /> : null}
        </MenuButton>

        {jobs?.length ? (
          <MenuList>
            {jobs?.map((job) => (
              <MenuItem key={job.nanoId}>
                <Flex justifyContent="space-between" alignItems="start" gap={2}>
                  <Stack spacing={2}>
                    <Heading
                      level="h2"
                      fontSize="md"
                      fontWeight="semibold"
                      lineHeight={1.5}
                      color="primary.500"
                    >
                      {renderJobTitle(job)}
                    </Heading>

                    <JobLocationHelper textStyle="body2Regular" job={job} />
                  </Stack>

                  <DeleteIcon mt={1} />
                </Flex>
              </MenuItem>
            ))}
          </MenuList>
        ) : null}
      </Menu>

      <Flex gap={2} alignItems="center">
        <Text>Job Priority</Text>
        <JobPriorityDropdown priority="high" />
      </Flex>

      <JobLocationHelper job={job} withIcon />

      <HStack spacing={2}>
        <JobsIcon w="20px" h="20px" color="gray.500" />

        <Text>{renderJobEmployment(job.employment) || '-'}</Text>
      </HStack>

      <HStack spacing={2}>
        <ClockIcon w="20px" h="20px" color="gray.500" />

        <Text>
          <RelativeDateHelper date={job.createdAt as string} />
        </Text>
      </HStack>

      <NextLinkHelper href={JobsRoutes.viewDetails(job.nanoId)} passHref>
        <PureButtonWithRefHelper
          as="a"
          size="medium"
          hierarchy="link"
          i18nText="Job Details"
          _hover={{ textDecoration: 'none' }}
        />
      </NextLinkHelper>

      <ViewJobCandidateDetailsJobHiringTeam />
    </HStack>
  );
}
