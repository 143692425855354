import { JobCandidateId } from '../jobCandidates/jobCandidatesTypes';
import { JobNanoId } from '../jobs/jobsTypes';
import { TalentPoolNanoId } from './talentPoolsTypes';

const baseTalentPoolPath = '/talent-pools';
const baseRelationshipsPath = `${baseTalentPoolPath}/relationships`;
const baseMyTalentPoolsPath = `${baseTalentPoolPath}/my-talent-pools`;

export class TalentPoolsRoutes {
  static index() {
    return baseTalentPoolPath;
  }

  static talentPool() {
    return `${baseTalentPoolPath}`;
  }

  static viewTalentPool(poolNanoId: TalentPoolNanoId) {
    return `${baseTalentPoolPath}/${poolNanoId}`;
  }

  static viewTalentPoolProspect(
    poolNanoId: TalentPoolNanoId,
    prospectId: JobCandidateId
  ) {
    return `${baseTalentPoolPath}/${poolNanoId}?prospectId=${prospectId}`;
  }

  static newTalentPool(poolNanoId: TalentPoolNanoId) {
    return `${baseTalentPoolPath}/${poolNanoId}/new`;
  }

  static requestProfiles(poolNanoId: TalentPoolNanoId, jobNanoId?: JobNanoId) {
    return `${baseTalentPoolPath}/${poolNanoId}/request?jobNanoId=${jobNanoId}`;
  }

  // MY TALENT POOL

  static myTalentPools() {
    return `${baseMyTalentPoolsPath}`;
  }

  static viewMyTalentPool(poolNanoId: TalentPoolNanoId) {
    return `${baseMyTalentPoolsPath}/${poolNanoId}`;
  }

  // RELATIONSHIPS

  static relationships() {
    return `${baseRelationshipsPath}/hires`;
  }

  static hires() {
    return `${baseRelationshipsPath}/hires`;
  }

  static coWorkers() {
    return `${baseRelationshipsPath}/co-workers`;
  }

  static referrals() {
    return `${baseRelationshipsPath}/referrals`;
  }
}
