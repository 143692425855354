import { Collapse, Stack } from '@chakra-ui/react';
import { CheckboxField } from '../../../../../../../../helpers/forms/formFields/CheckboxField';
import { Heading } from '../../../../../../../../helpers/Heading';
import {
  CandidateSubmissionBaseSalary,
  CandidateSubmissionFields,
  CandidateSubmissionShareSettingsField,
  CandidateSubmissionShareSettingsFields
} from '../../../../../../../candidateSubmissions/candidateSubmissionsTypes';
import { AddCandidateSubmissionFormData } from '../../../../../../../candidateSubmissions/components/forms/AddCandidateSubmissionForm/AddCandidateSubmissionForm.types';
import {
  BaseSalaryInputFormField,
  BaseSalaryInputFormFieldProps
} from '../../../../../../../common/components/formFields/BaseSlaryInputFormField';
import { useViewJobCandidateDetailsSubmissionContext } from '../../../../contexts/ViewJobCandidateDetailsSubmissionContext';
import { CustomiseCandidateSubmissionItem } from '../CustomiseCandidateSubmissionItem';
import { CustomiseCandidateSubmissionSubItem } from '../CustomiseCandidateSubmissionSubItem';

export default function CustomiseCandidateSubmissionProfile() {
  const {
    shareSettings,
    baseSalary,
    showCandidatePreferredSalary,
    control,
    validationErrors,
    setCandidateSubmissionFormValue
  } = useViewJobCandidateDetailsSubmissionContext();
  const isSharedCandidateName = shareSettings.includes(
    CandidateSubmissionShareSettingsFields.ProfileCandidateName as CandidateSubmissionShareSettingsField
  );

  const baseSalaryRules = {
    validate: (value: CandidateSubmissionBaseSalary) => {
      if (showCandidatePreferredSalary) {
        return true;
      } else {
        return !!value || 'Base Salary is required';
      }
    }
  };

  return (
    <Stack spacing={3}>
      <Heading level="h5" textStyle="body1Medium" fontWeight="semibold">
        Customize submittal profile
      </Heading>

      <Collapse in={!showCandidatePreferredSalary}>
        <BaseSalaryInputFormField
          label="Submission Salary"
          control={control}
          name={CandidateSubmissionFields.BASE_SALARY}
          errorMessage={validationErrors.baseSalaryValidationError}
          isDisabled={showCandidatePreferredSalary}
          rules={
            baseSalaryRules as BaseSalaryInputFormFieldProps<AddCandidateSubmissionFormData>['rules']
          }
        />
      </Collapse>

      <CheckboxField
        label="Show candidates preferred base salary"
        isChecked={showCandidatePreferredSalary}
        onChange={(e) => {
          setCandidateSubmissionFormValue(
            CandidateSubmissionFields.SHOW_CANDIDATE_PREFERRED_SALARY,
            e.target.checked as never
          );
          setCandidateSubmissionFormValue(
            CandidateSubmissionFields.BASE_SALARY,
            '' as never
          );
        }}
      />

      <Collapse in={showCandidatePreferredSalary || !!baseSalary}>
        <Stack spacing={2}>
          <CustomiseCandidateSubmissionItem
            label="Photos"
            value={
              CandidateSubmissionShareSettingsFields.ProfilePhotos as CandidateSubmissionShareSettingsField
            }
          />

          <CustomiseCandidateSubmissionItem
            label="Candidate name"
            value={
              CandidateSubmissionShareSettingsFields.ProfileCandidateName as CandidateSubmissionShareSettingsField
            }
          />

          <CustomiseCandidateSubmissionItem
            label="Job Title"
            isDisabled={!isSharedCandidateName}
            value={
              CandidateSubmissionShareSettingsFields.ProfileJobTitle as CandidateSubmissionShareSettingsField
            }
          />

          <CustomiseCandidateSubmissionItem
            label="Location"
            value={
              CandidateSubmissionShareSettingsFields.ProfileLocation as CandidateSubmissionShareSettingsField
            }
          />

          <CustomiseCandidateSubmissionItem
            label="LinkedIn URL"
            value={
              CandidateSubmissionShareSettingsFields.ProfileLinkedInUrl as CandidateSubmissionShareSettingsField
            }
          />

          <CustomiseCandidateSubmissionItem
            label="Current Company"
            value={
              CandidateSubmissionShareSettingsFields.ProfileCurrentCompany as CandidateSubmissionShareSettingsField
            }
          />

          <CustomiseCandidateSubmissionItem
            label="Email"
            value={
              CandidateSubmissionShareSettingsFields.ProfileEmail as CandidateSubmissionShareSettingsField
            }
          />

          <CustomiseCandidateSubmissionItem
            label="Phone number"
            value={
              CandidateSubmissionShareSettingsFields.ProfilePhoneNumber as CandidateSubmissionShareSettingsField
            }
          />

          <CustomiseCandidateSubmissionItem
            label="Files"
            value={
              CandidateSubmissionShareSettingsFields.Files as CandidateSubmissionShareSettingsField
            }
          />

          <CustomiseCandidateSubmissionItem
            label="Activities"
            value={
              CandidateSubmissionShareSettingsFields.Activities as CandidateSubmissionShareSettingsField
            }
          >
            <CustomiseCandidateSubmissionSubItem
              value={
                CandidateSubmissionShareSettingsFields.ActivitiesAllActivity as CandidateSubmissionShareSettingsField
              }
              label="All Activity"
            />

            <CustomiseCandidateSubmissionSubItem
              value={
                CandidateSubmissionShareSettingsFields.ActivitiesPhoneCalls as CandidateSubmissionShareSettingsField
              }
              label="Phone Calls"
            />

            <CustomiseCandidateSubmissionSubItem
              value={
                CandidateSubmissionShareSettingsFields.ActivitiesCallConnections as CandidateSubmissionShareSettingsField
              }
              label="Call Connections"
            />

            <CustomiseCandidateSubmissionSubItem
              value={
                CandidateSubmissionShareSettingsFields.ActivitiesEmails as CandidateSubmissionShareSettingsField
              }
              label="Emails"
            />

            <CustomiseCandidateSubmissionSubItem
              value={
                CandidateSubmissionShareSettingsFields.ActivitiesMeetings as CandidateSubmissionShareSettingsField
              }
              label="Meetings"
            />

            <CustomiseCandidateSubmissionSubItem
              value={
                CandidateSubmissionShareSettingsFields.ActivitiesStageChanges as CandidateSubmissionShareSettingsField
              }
              label="Stage Changes"
            />

            <CustomiseCandidateSubmissionSubItem
              value={
                CandidateSubmissionShareSettingsFields.ActivitiesComments as CandidateSubmissionShareSettingsField
              }
              label="Comments"
            />

            <CustomiseCandidateSubmissionSubItem
              value={
                CandidateSubmissionShareSettingsFields.ActivitiesTasks as CandidateSubmissionShareSettingsField
              }
              label="Tasks"
            />
          </CustomiseCandidateSubmissionItem>

          <CustomiseCandidateSubmissionItem
            label="Experience"
            value={
              CandidateSubmissionShareSettingsFields.ProfileExperience as CandidateSubmissionShareSettingsField
            }
          />

          <CustomiseCandidateSubmissionItem
            label="Education"
            value={
              CandidateSubmissionShareSettingsFields.ProfileEducation as CandidateSubmissionShareSettingsField
            }
          />

          <CustomiseCandidateSubmissionItem
            label="Certification/License"
            value={
              CandidateSubmissionShareSettingsFields.ProfileCertificationLicense as CandidateSubmissionShareSettingsField
            }
          />
        </Stack>
      </Collapse>
    </Stack>
  );
}
