import React, { useCallback, useState } from 'react';
import {
  DeepPartial,
  FieldValues,
  useForm,
  SubmitHandler,
  SubmitErrorHandler,
  Path
} from 'react-hook-form';
import { AnyObjectSchema } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import get from 'lodash/get';
import isObject from 'lodash/isObject';
import has from 'lodash/has';

import { IsLoading, ErrorMessage } from '../../../../types';

interface ReactHookFormOptions<FormDataType> {
  defaultValues: DeepPartial<FormDataType>;
  schema?: AnyObjectSchema;
}

interface SubmitReactHookFormOptions<FormDataType extends FieldValues> {
  onSubmit: SubmitHandler<FormDataType>;
  onError?: SubmitErrorHandler<FormDataType>;
}

function useReactHookForm<FormDataType extends FieldValues>({
  defaultValues,
  schema
}: ReactHookFormOptions<FormDataType>) {
  const [isLoading, setIsLoading] = useState<IsLoading>(false as IsLoading);
  const [errorMessage, setErrorMessage] = useState<ErrorMessage>(undefined);
  const {
    control,
    watch,
    formState: {
      dirtyFields,
      isDirty,
      isValid,
      isValidating,
      isSubmitSuccessful,
      isSubmitting,
      isSubmitted,
      touchedFields,
      errors
    },
    handleSubmit,
    register,
    reset,
    setError,
    setValue,
    trigger
  } = useForm<FormDataType>({
    defaultValues,
    resolver: schema ? yupResolver(schema) : undefined
  });

  return {
    control,
    dirtyFields,
    isDirty,
    isValid,
    isValidating,
    isSubmitSuccessful,
    isSubmitting,
    isSubmitted,
    touchedFields,
    errors,
    register,
    resetForm: reset,
    setError,
    setValue,
    isLoading,
    trigger,
    errorMessage,
    watch,
    handleSubmitReactHookForm: useCallback<
      ({
        onSubmit,
        onError
      }: SubmitReactHookFormOptions<FormDataType>) => (
        e?: React.BaseSyntheticEvent
      ) => Promise<void>
    >(
      ({ onSubmit, onError }) =>
        handleSubmit(
          async (data) => {
            setErrorMessage(undefined);
            setIsLoading(true as IsLoading);
            try {
              const response = await onSubmit?.(data);
              setIsLoading(false as IsLoading);
              return response;
            } catch (error) {
              setIsLoading(false as IsLoading);
              if (isObject(error)) {
                if (has(error, 'response.data.message')) {
                  setErrorMessage(get(error, 'response.data.message'));
                  return;
                }

                if (has(error, 'message')) {
                  setErrorMessage(get(error, 'message'));
                  return;
                }

                Object.keys(error).forEach((errorKey) => {
                  if (errorKey === 'fullMessages') {
                    return;
                  }

                  if (Array.isArray(get(error, errorKey))) {
                    setError(errorKey as Path<FormDataType>, {
                      type: 'server',
                      message: get(error, errorKey).join(', ')
                    });
                  }
                });
              }
            }
          },
          (errors) => onError?.(errors)
        ),
      [handleSubmit, setIsLoading, setError]
    )
  };
}

export default useReactHookForm;
