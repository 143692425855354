import { MayBeCompanyId } from '../../../companies/companiesTypes';

import { FetchJobsFilters, JobFields, JobStatuses } from '../../jobsTypes';

export interface JobsFiltersProps {
  companyId?: MayBeCompanyId;
  my?: boolean;
  extraFilters?: FetchJobsFilters;
}

export function activeJobsInCompanyFilters({
  companyId,
  my,
  extraFilters
}: JobsFiltersProps) {
  return {
    ...(my
      ? {
          [JobFields.MY]: true
        }
      : {
          [JobFields.HIRING_MANAGER_ID]: {
            operator: 'not.is',
            value: null
          }
        }),
    [JobFields.ARCHIVED_AT]: {
      operator: 'is',
      value: null
    },
    [JobFields.COMPANY_ID]: {
      operator: 'eq',
      value: companyId || '0'
    },
    ...(extraFilters || {})
  } as unknown as FetchJobsFilters;
}

export function publishedJobsInCompanyFilters({
  companyId,
  my,
  extraFilters
}: JobsFiltersProps) {
  return {
    ...(my
      ? {
          [JobFields.MY]: true
        }
      : {
          [JobFields.HIRING_MANAGER_ID]: {
            operator: 'not.is',
            value: null
          }
        }),
    [JobFields.CITY_ID]: {
      operator: 'not.is',
      value: null
    },
    [JobFields.ARCHIVED_AT]: {
      operator: 'is',
      value: null
    },
    [JobFields.COMPANY_ID]: {
      operator: 'eq',
      value: companyId || '0'
    },
    [JobFields.STATUS]: {
      operator: 'eq',
      value: JobStatuses.PUBLISHED
    },
    ...(extraFilters || {})
  } as unknown as FetchJobsFilters;
}

export function closedJobsInCompanyFilters({
  companyId,
  my,
  extraFilters
}: JobsFiltersProps) {
  return {
    ...(my
      ? {
          [JobFields.MY]: true
        }
      : {
          [JobFields.HIRING_MANAGER_ID]: {
            operator: 'not.is',
            value: null
          }
        }),
    [JobFields.CITY_ID]: {
      operator: 'not.is',
      value: null
    },
    [JobFields.ARCHIVED_AT]: {
      operator: 'is',
      value: null
    },
    [JobFields.COMPANY_ID]: {
      operator: 'eq',
      value: companyId || '0'
    },
    [JobFields.STATUS]: {
      operator: 'eq',
      value: JobStatuses.CLOSED
    },
    ...(extraFilters || {})
  } as unknown as FetchJobsFilters;
}

export function draftJobsInCompanyFilters({
  companyId,
  my,
  extraFilters
}: JobsFiltersProps) {
  return {
    ...(my
      ? {
          [JobFields.MY]: true
        }
      : {}),
    [JobFields.ARCHIVED_AT]: {
      operator: 'is',
      value: null
    },
    [JobFields.COMPANY_ID]: {
      operator: 'eq',
      value: companyId || '0'
    },
    [JobFields.STATUS]: {
      operator: 'eq',
      value: JobStatuses.DRAFT
    },
    ...(extraFilters || {})
  } as unknown as FetchJobsFilters;
}

export const activeJobsFilters = {
  [JobFields.HIRING_MANAGER_ID]: {
    operator: 'not.is',
    value: null
  },
  // [JobFields.CITY_ID]: {
  //   operator: 'not.is',
  //   value: null
  // },
  [JobFields.ARCHIVED_AT]: {
    operator: 'is',
    value: null
  }
} as unknown as FetchJobsFilters;

export function inActiveJobsInCompanyFilters(
  companyId: MayBeCompanyId | undefined
) {
  return {
    [JobFields.ARCHIVED_AT]: {
      operator: 'not.is',
      value: null
    },
    [JobFields.COMPANY_ID]: {
      operator: 'eq',
      value: companyId || '0'
    }
  } as unknown as FetchJobsFilters;
}

export const inActiveJobsFilters = {
  [JobFields.ARCHIVED_AT]: {
    operator: 'not.is',
    value: null
  }
} as unknown as FetchJobsFilters;

export function archivedJobsInCompanyFilters(
  companyId: MayBeCompanyId | undefined
) {
  return {
    [JobFields.ARCHIVED_AT]: {
      operator: 'not.is',
      value: null
    },
    [JobFields.COMPANY_ID]: {
      operator: 'eq',
      value: companyId || '0'
    }
  } as unknown as FetchJobsFilters;
}
