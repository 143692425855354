import { JobCandidateNanoId } from '../jobCandidates/jobCandidatesTypes';
import { JobResumeNanoId } from '../jobResumes/jobResumesTypes';
import { JobNanoId } from '../jobs/jobsTypes';
import { TalentPoolNanoId } from '../talentPools/talentPoolsTypes';

import { PipelineRoutes } from './PipelineRoutes';

const baseKanabanPath = PipelineRoutes.kanban();

export class PipelineKanbanRoutes {
  static index() {
    return `${baseKanabanPath}/jobs`;
  }

  static hire() {
    return baseKanabanPath;
  }

  static inactive() {
    return `${baseKanabanPath}/inactive`;
  }

  static screening() {
    return `${baseKanabanPath}/screening`;
  }

  static talentPool() {
    return `${baseKanabanPath}/talent-pool`;
  }

  static viewTalentPool(poolNanoId: TalentPoolNanoId) {
    return `${baseKanabanPath}/talent-pool/${poolNanoId}`;
  }
  static newTalentPool(poolNanoId: TalentPoolNanoId) {
    return `${baseKanabanPath}/talent-pool/${poolNanoId}/new`;
  }

  static getTalentPool() {
    return `${baseKanabanPath}/get-candidates`;
  }

  static quickActions() {
    return `${baseKanabanPath}/quick-actions`;
  }

  static post() {
    return `${baseKanabanPath}/post`;
  }

  static postTalents(jobNanoId: JobNanoId) {
    return `${baseKanabanPath}/post/${jobNanoId}/talents`;
  }

  static postDescription(jobNanoId: JobNanoId) {
    return `${baseKanabanPath}/post/${jobNanoId}/description`;
  }

  static postDetails(jobNanoId: JobNanoId) {
    return `${baseKanabanPath}/post/${jobNanoId}/details`;
  }

  static postSelection(jobNanoId: JobNanoId) {
    return `${baseKanabanPath}/post/${jobNanoId}/selection`;
  }

  static postIntake(jobNanoId: JobNanoId) {
    return `${baseKanabanPath}/post/${jobNanoId}/intake`;
  }

  static postScreening(jobNanoId: JobNanoId) {
    return `${baseKanabanPath}/post/${jobNanoId}/screening`;
  }

  static postWorkflow(jobNanoId: JobNanoId) {
    return `${baseKanabanPath}/post/${jobNanoId}/workflow`;
  }

  static postAutomation(jobNanoId: JobNanoId) {
    return `${baseKanabanPath}/post/${jobNanoId}/automation`;
  }

  static postJobCandidates(jobNanoId: JobNanoId) {
    return `${baseKanabanPath}/post/${jobNanoId}/candidates`;
  }

  static postJobCandidatesList(jobNanoId: JobNanoId) {
    return `${baseKanabanPath}/post/${jobNanoId}/candidates/list`;
  }

  static postJobCandidatesValidation(jobNanoId: JobNanoId) {
    return `${baseKanabanPath}/post/${jobNanoId}/candidates/validation`;
  }

  static postCandidatesLead(jobNanoId: JobNanoId) {
    return `${baseKanabanPath}/post/${jobNanoId}/candidates/lead`;
  }

  static postCandidatesLeadEdit(
    jobNanoId: JobNanoId,
    jobCandidateNanoId: JobCandidateNanoId
  ) {
    return `${baseKanabanPath}/post/${jobNanoId}/candidates/lead/${jobCandidateNanoId}/edit`;
  }

  static postCandidatesLeadInfo(
    jobNanoId: JobNanoId,
    jobCandidateNanoId: JobCandidateNanoId
  ) {
    return `${baseKanabanPath}/post/${jobNanoId}/candidates/lead/${jobCandidateNanoId}/info`;
  }

  static postCandidatesLeadProfile(
    jobNanoId: JobNanoId,
    jobCandidateNanoId: JobCandidateNanoId
  ) {
    return `${baseKanabanPath}/post/${jobNanoId}/candidates/lead/${jobCandidateNanoId}`;
  }

  static postJobCandidatesJobResumeAbout(
    jobNanoId: JobNanoId,
    jobResumeNanoId: JobResumeNanoId
  ) {
    return `${baseKanabanPath}/post/${jobNanoId}/candidates/resume/${jobResumeNanoId}/about`;
  }

  static postJobCandidatesJobResumeExperience(
    jobNanoId: JobNanoId,
    jobResumeNanoId: JobResumeNanoId
  ) {
    return `${baseKanabanPath}/post/${jobNanoId}/candidates/resume/${jobResumeNanoId}/experience`;
  }

  static postJobCandidatesJobResumeEducation(
    jobNanoId: JobNanoId,
    jobResumeNanoId: JobResumeNanoId
  ) {
    return `${baseKanabanPath}/post/${jobNanoId}/candidates/resume/${jobResumeNanoId}/education`;
  }

  static postJobCandidatesJobResumeCertification(
    jobNanoId: JobNanoId,
    jobResumeNanoId: JobResumeNanoId
  ) {
    return `${baseKanabanPath}/post/${jobNanoId}/candidates/resume/${jobResumeNanoId}/certification`;
  }

  static postJobCandidatesJobResumeSkills(
    jobNanoId: JobNanoId,
    jobResumeNanoId: JobResumeNanoId
  ) {
    return `${baseKanabanPath}/post/${jobNanoId}/candidates/resume/${jobResumeNanoId}/skills`;
  }

  static postJobCandidatesJobResumeProfile(
    jobNanoId: JobNanoId,
    jobResumeNanoId: JobResumeNanoId
  ) {
    return `${baseKanabanPath}/post/${jobNanoId}/candidates/resume/${jobResumeNanoId}/profile`;
  }

  static viewIndex() {
    return `${baseKanabanPath}/view`;
  }

  static viewCandidates(jobNanoId: JobNanoId) {
    return `${baseKanabanPath}/view/${jobNanoId}/candidates`;
  }

  static viewCandidateDetails(
    jobNanoId: JobNanoId,
    jobCandidateNanoId: JobCandidateNanoId
  ) {
    return `${baseKanabanPath}/view/${jobNanoId}/candidates/${jobCandidateNanoId}`;
  }

  static viewCandidateDetailsScoreCards(
    jobNanoId: JobNanoId,
    jobCandidateNanoId: JobCandidateNanoId
  ) {
    return `${baseKanabanPath}/view/${jobNanoId}/candidates/${jobCandidateNanoId}/score-cards`;
  }

  static viewCandidateDetailsReferences(
    jobNanoId: JobNanoId,
    jobCandidateNanoId: JobCandidateNanoId
  ) {
    return `${baseKanabanPath}/view/${jobNanoId}/candidates/${jobCandidateNanoId}/references`;
  }

  static viewCandidateDetailsProjects(
    jobNanoId: JobNanoId,
    jobCandidateNanoId: JobCandidateNanoId
  ) {
    return `${baseKanabanPath}/view/${jobNanoId}/candidates/${jobCandidateNanoId}/projects`;
  }

  static viewCandidateDetailsProfile(
    jobNanoId: JobNanoId,
    jobCandidateNanoId: JobCandidateNanoId
  ) {
    return `${baseKanabanPath}/view/${jobNanoId}/candidates/${jobCandidateNanoId}/profile`;
  }

  static editCandidateDetailsProfile(
    jobNanoId: JobNanoId,
    jobCandidateNanoId: JobCandidateNanoId
  ) {
    return `${baseKanabanPath}/view/${jobNanoId}/candidates/${jobCandidateNanoId}/edit`;
  }

  static viewCandidateDetailsFiles(
    jobNanoId: JobNanoId,
    jobCandidateNanoId: JobCandidateNanoId
  ) {
    return `${baseKanabanPath}/view/${jobNanoId}/candidates/${jobCandidateNanoId}/files`;
  }

  static viewCandidateDetailsAssessment(
    jobNanoId: JobNanoId,
    jobCandidateNanoId: JobCandidateNanoId
  ) {
    return `${baseKanabanPath}/view/${jobNanoId}/candidates/${jobCandidateNanoId}/assessment`;
  }

  static viewCandidateDetailsActivities(
    jobNanoId: JobNanoId,
    jobCandidateNanoId: JobCandidateNanoId
  ) {
    return `${baseKanabanPath}/view/${jobNanoId}/candidates/${jobCandidateNanoId}/activities`;
  }

  static viewCandidateDetailsAllActivities(
    jobNanoId: JobNanoId,
    jobCandidateNanoId: JobCandidateNanoId
  ) {
    return `${baseKanabanPath}/view/${jobNanoId}/candidates/${jobCandidateNanoId}/activities/all-activities`;
  }

  static viewCandidateDetailsCallConnections(
    jobNanoId: JobNanoId,
    jobCandidateNanoId: JobCandidateNanoId
  ) {
    return `${baseKanabanPath}/view/${jobNanoId}/candidates/${jobCandidateNanoId}/activities/call-connections`;
  }

  static viewCandidateDetailsCallEmails(
    jobNanoId: JobNanoId,
    jobCandidateNanoId: JobCandidateNanoId
  ) {
    return `${baseKanabanPath}/view/${jobNanoId}/candidates/${jobCandidateNanoId}/activities/emails`;
  }

  static viewCandidateDetailsComments(
    jobNanoId: JobNanoId,
    jobCandidateNanoId: JobCandidateNanoId
  ) {
    return `${baseKanabanPath}/view/${jobNanoId}/candidates/${jobCandidateNanoId}/activities/comments`;
  }

  static viewCandidateDetailsMeetings(
    jobNanoId: JobNanoId,
    jobCandidateNanoId: JobCandidateNanoId
  ) {
    return `${baseKanabanPath}/view/${jobNanoId}/candidates/${jobCandidateNanoId}/activities/meetings`;
  }

  static viewCandidateDetailsTasks(
    jobNanoId: JobNanoId,
    jobCandidateNanoId: JobCandidateNanoId
  ) {
    return `${baseKanabanPath}/view/${jobNanoId}/candidates/${jobCandidateNanoId}/activities/tasks`;
  }

  static viewCandidateDetailsPhoneCalls(
    jobNanoId: JobNanoId,
    jobCandidateNanoId: JobCandidateNanoId
  ) {
    return `${baseKanabanPath}/view/${jobNanoId}/candidates/${jobCandidateNanoId}/activities/phone-calls`;
  }

  static viewCandidateDetailsStageChanges(
    jobNanoId: JobNanoId,
    jobCandidateNanoId: JobCandidateNanoId
  ) {
    return `${baseKanabanPath}/view/${jobNanoId}/candidates/${jobCandidateNanoId}/activities/stage-changes`;
  }

  static viewSourcedCandidates(jobNanoId: JobNanoId) {
    return `${baseKanabanPath}/view/${jobNanoId}/candidates/sourced`;
  }

  static viewDetails(jobNanoId: JobNanoId) {
    return `${baseKanabanPath}/view/${jobNanoId}/details`;
  }

  static viewTimeline(jobNanoId: JobNanoId) {
    return `${baseKanabanPath}/view/${jobNanoId}/timeline`;
  }

  static viewScreening(jobNanoId: JobNanoId) {
    return `${baseKanabanPath}/view/${jobNanoId}/screening`;
  }

  static viewSettings(jobNanoId: JobNanoId) {
    return `${baseKanabanPath}/view/${jobNanoId}/settings`;
  }

  static editIndex() {
    return `${baseKanabanPath}/edit`;
  }

  static editPreferences(jobNanoId: JobNanoId) {
    return `${baseKanabanPath}/edit/${jobNanoId}/preferences`;
  }

  static editTeam(jobNanoId: JobNanoId) {
    return `${baseKanabanPath}/edit/${jobNanoId}/team`;
  }

  static editDescription(jobNanoId: JobNanoId) {
    return `${baseKanabanPath}/edit/${jobNanoId}/description`;
  }

  static editSkills(jobNanoId: JobNanoId) {
    return `${baseKanabanPath}/edit/${jobNanoId}/skills`;
  }

  static editHiringWorkflow(jobNanoId: JobNanoId) {
    return `${baseKanabanPath}/edit/${jobNanoId}/workflow`;
  }

  static editScoreCard(jobNanoId: JobNanoId) {
    return `${baseKanabanPath}/edit/${jobNanoId}/scorecard`;
  }

  static editAutomation(jobNanoId: JobNanoId) {
    return `${baseKanabanPath}/edit/${jobNanoId}/automation`;
  }
}
