import { JobCandidateNanoId } from '../../../jobCandidates/jobCandidatesTypes';
import { JobsRoutes } from '../../JobsRoutes';
import { JobNanoId } from '../../jobsTypes';
import { JobCandidateDetailsPageReferrer } from './ViewJobCandidateDetailsPage.types';

type BasePathType = {
  slug: string;
  jobNanoId: JobNanoId;
  jobCandidateNanoId: JobCandidateNanoId;
  referrer?: JobCandidateDetailsPageReferrer;
};

const basePath = ({
  slug,
  jobNanoId,
  jobCandidateNanoId,
  referrer
}: BasePathType) => {
  const base = `${JobsRoutes.viewCandidateDetails(
    jobNanoId,
    jobCandidateNanoId
  )}/${slug}`;
  return referrer ? `${base}?referrer=${referrer}` : base;
};

export class ViewJobCandidateDetailsRoutes {
  static index(
    jobNanoId: JobNanoId,
    jobCandidateNanoId: JobCandidateNanoId,
    referrer?: JobCandidateDetailsPageReferrer
  ) {
    return `${basePath({
      jobNanoId,
      jobCandidateNanoId,
      referrer,
      slug: ''
    })}`;
  }

  static submission(
    jobNanoId: JobNanoId,
    jobCandidateNanoId: JobCandidateNanoId,
    referrer?: JobCandidateDetailsPageReferrer
  ) {
    return `${basePath({
      jobNanoId,
      jobCandidateNanoId,
      referrer,
      slug: 'submission'
    })}`;
  }

  static edit(
    jobNanoId: JobNanoId,
    jobCandidateNanoId: JobCandidateNanoId,
    referrer?: JobCandidateDetailsPageReferrer
  ) {
    return `${basePath({
      jobNanoId,
      jobCandidateNanoId,
      referrer,
      slug: 'edit'
    })}`;
  }

  static profile(
    jobNanoId: JobNanoId,
    jobCandidateNanoId: JobCandidateNanoId,
    referrer?: JobCandidateDetailsPageReferrer
  ) {
    return `${basePath({
      jobNanoId,
      jobCandidateNanoId,
      referrer,
      slug: 'profile'
    })}`;
  }

  static tasks(
    jobNanoId: JobNanoId,
    jobCandidateNanoId: JobCandidateNanoId,
    referrer?: JobCandidateDetailsPageReferrer
  ) {
    return `${basePath({
      jobNanoId,
      jobCandidateNanoId,
      referrer,
      slug: 'tasks'
    })}`;
  }

  static files(
    jobNanoId: JobNanoId,
    jobCandidateNanoId: JobCandidateNanoId,
    referrer?: JobCandidateDetailsPageReferrer
  ) {
    return `${basePath({
      jobNanoId,
      jobCandidateNanoId,
      referrer,
      slug: 'files'
    })}`;
  }

  static assessment(
    jobNanoId: JobNanoId,
    jobCandidateNanoId: JobCandidateNanoId,
    referrer?: JobCandidateDetailsPageReferrer
  ) {
    return `${basePath({
      jobNanoId,
      jobCandidateNanoId,
      referrer,
      slug: 'assessment'
    })}`;
  }

  static projects(
    jobNanoId: JobNanoId,
    jobCandidateNanoId: JobCandidateNanoId,
    referrer?: JobCandidateDetailsPageReferrer
  ) {
    return `${basePath({
      jobNanoId,
      jobCandidateNanoId,
      referrer,
      slug: 'projects'
    })}`;
  }

  static references(
    jobNanoId: JobNanoId,
    jobCandidateNanoId: JobCandidateNanoId,
    referrer?: JobCandidateDetailsPageReferrer
  ) {
    return `${basePath({
      jobNanoId,
      jobCandidateNanoId,
      referrer,
      slug: 'references'
    })}`;
  }

  static activities(
    jobNanoId: JobNanoId,
    jobCandidateNanoId: JobCandidateNanoId,
    referrer?: JobCandidateDetailsPageReferrer
  ) {
    return `${basePath({
      jobNanoId,
      jobCandidateNanoId,
      referrer,
      slug: 'activities'
    })}`;
  }

  static scoreCards(
    jobNanoId: JobNanoId,
    jobCandidateNanoId: JobCandidateNanoId,
    referrer?: JobCandidateDetailsPageReferrer
  ) {
    return `${basePath({
      jobNanoId,
      jobCandidateNanoId,
      referrer,
      slug: 'score-cards'
    })}`;
  }
}
