import { Stack } from '@chakra-ui/react';
import { Heading } from '../../../../../../../../helpers/Heading';
import { FilesIcon } from '../../../../../../../../icons/FilesIcon';
import { PreferencesIcon } from '../../../../../../../../icons/PreferencesIcon';
import { ProjectsIcon } from '../../../../../../../../icons/ProjectsIcon';
import { QualificationsIcon } from '../../../../../../../../icons/QualificationsIcon';
import { QuestionMarkIcon } from '../../../../../../../../icons/QuestionMarkIcon';
import { ReferencesIcon } from '../../../../../../../../icons/ReferencesIcon';
import { SettingsIcon } from '../../../../../../../../icons/SettingsIcon';
import { CustomiseCandidateSubmissionItem } from '../CustomiseCandidateSubmissionItem';
import { CustomiseCandidateSubmissionSubItem } from '../CustomiseCandidateSubmissionSubItem';
import {
  CandidateSubmissionShareSettingsField,
  CandidateSubmissionShareSettingsFields
} from '../../../../../../../candidateSubmissions/candidateSubmissionsTypes';

export default function CustomiseCandidateSubmissionAssessment() {
  return (
    <Stack spacing={2}>
      <Heading level="h5" textStyle="body1Medium" fontWeight="semibold">
        Candidate assessment
      </Heading>

      <CustomiseCandidateSubmissionItem
        label="Preferences"
        icon={PreferencesIcon}
        value={
          CandidateSubmissionShareSettingsFields.AssessmentPreferences as CandidateSubmissionShareSettingsField
        }
      >
        <CustomiseCandidateSubmissionSubItem
          value={
            CandidateSubmissionShareSettingsFields.AssessmentPreferencesEmployment as CandidateSubmissionShareSettingsField
          }
          label="Employment Status"
        />

        <CustomiseCandidateSubmissionSubItem
          value={
            CandidateSubmissionShareSettingsFields.AssessmentPreferencesWorkplace as CandidateSubmissionShareSettingsField
          }
          label="Workplace"
        />

        <CustomiseCandidateSubmissionSubItem
          value={
            CandidateSubmissionShareSettingsFields.AssessmentPreferencesPreferredLocation as CandidateSubmissionShareSettingsField
          }
          label="Preferred Location"
        />

        <CustomiseCandidateSubmissionSubItem
          value={
            CandidateSubmissionShareSettingsFields.AssessmentPreferencesSalary as CandidateSubmissionShareSettingsField
          }
          label="Salary"
        />

        <CustomiseCandidateSubmissionSubItem
          value={
            CandidateSubmissionShareSettingsFields.AssessmentPreferencesEmployment as CandidateSubmissionShareSettingsField
          }
          label="Phone call availability"
        />
      </CustomiseCandidateSubmissionItem>

      <CustomiseCandidateSubmissionItem
        label="Qualifications"
        icon={QualificationsIcon}
        value={
          CandidateSubmissionShareSettingsFields.AssessmentQualifications as CandidateSubmissionShareSettingsField
        }
      >
        <CustomiseCandidateSubmissionSubItem
          value={
            CandidateSubmissionShareSettingsFields.AssessmentQualificationsAbout as CandidateSubmissionShareSettingsField
          }
          label="About"
        />

        <CustomiseCandidateSubmissionSubItem
          value={
            CandidateSubmissionShareSettingsFields.AssessmentQualificationsJobFunction as CandidateSubmissionShareSettingsField
          }
          label="Job Function"
        />

        <CustomiseCandidateSubmissionSubItem
          value={
            CandidateSubmissionShareSettingsFields.AssessmentQualificationsWorkAuthorization as CandidateSubmissionShareSettingsField
          }
          label="Work Authorization"
        />

        <CustomiseCandidateSubmissionSubItem
          value={
            CandidateSubmissionShareSettingsFields.AssessmentQualificationsIndustry as CandidateSubmissionShareSettingsField
          }
          label="Industry"
        />

        <CustomiseCandidateSubmissionSubItem
          value={
            CandidateSubmissionShareSettingsFields.AssessmentQualificationsSpecialization as CandidateSubmissionShareSettingsField
          }
          label="Specialization"
        />

        <CustomiseCandidateSubmissionSubItem
          value={
            CandidateSubmissionShareSettingsFields.AssessmentQualificationsIndustryExperience as CandidateSubmissionShareSettingsField
          }
          label="Industry Experience"
        />

        <CustomiseCandidateSubmissionSubItem
          value={
            CandidateSubmissionShareSettingsFields.AssessmentQualificationsCareerPath as CandidateSubmissionShareSettingsField
          }
          label="Career Path"
        />
      </CustomiseCandidateSubmissionItem>

      <CustomiseCandidateSubmissionItem
        label="Skills Expertise"
        icon={SettingsIcon}
        value={
          CandidateSubmissionShareSettingsFields.AssessmentSkillsExpertise as CandidateSubmissionShareSettingsField
        }
      >
        <CustomiseCandidateSubmissionSubItem
          value={
            CandidateSubmissionShareSettingsFields.AssessmentSkillsExpertiseComputerSkills as CandidateSubmissionShareSettingsField
          }
          label="Computer Skills"
        />

        <CustomiseCandidateSubmissionSubItem
          value={
            CandidateSubmissionShareSettingsFields.AssessmentSkillsExpertiseIndustryKnowledge as CandidateSubmissionShareSettingsField
          }
          label="Industry Knowledge"
        />
      </CustomiseCandidateSubmissionItem>

      <CustomiseCandidateSubmissionItem
        label="Questions"
        icon={QuestionMarkIcon}
        value={
          CandidateSubmissionShareSettingsFields.AssessmentQuestions as CandidateSubmissionShareSettingsField
        }
      />

      <CustomiseCandidateSubmissionItem
        label="Projects"
        icon={ProjectsIcon}
        value={
          CandidateSubmissionShareSettingsFields.AssessmentProjects as CandidateSubmissionShareSettingsField
        }
      />

      <CustomiseCandidateSubmissionItem
        label="References"
        icon={ReferencesIcon}
        value={
          CandidateSubmissionShareSettingsFields.AssessmentReferences as CandidateSubmissionShareSettingsField
        }
      />

      <CustomiseCandidateSubmissionItem
        label="Updated Resume"
        icon={FilesIcon}
        value={
          CandidateSubmissionShareSettingsFields.AssessmentUpdatedResume as CandidateSubmissionShareSettingsField
        }
      />
    </Stack>
  );
}
