import { Box, Flex, Stack } from '@chakra-ui/react';
import { useFetchJobCandidateByNanoId } from '../../../../../jobCandidates/hooks/useFetchJobCandidateByNanoId';
import { JobCandidatesCache } from '../../../../../jobCandidates/JobCandidatesCache';
import {
  FetchJobCandidateEnabled,
  JobCandidateNanoId
} from '../../../../../jobCandidates/jobCandidatesTypes';
import {
  FetchJobCandidateResponse,
  fetchJobCandidateQuery
} from '../../../../../jobCandidates/queries/fetchJobCandidate.query';
import {
  JobId,
  JobInterviewers,
  JobJobTitle,
  JobName,
  JobNanoId,
  MayBeJobCities,
  MayBeJobCity,
  MayBeJobCompany,
  MayBeJobHiringManager
} from '../../../../jobsTypes';
import { useViewJobCandidateDetailsSubmissionContext } from '../../contexts/ViewJobCandidateDetailsSubmissionContext';
import { CustomiseCandidateSubmissionAssessment } from './components/CustomiseCandidateSubmissionAssessment';
import { CustomiseCandidateSubmissionEvaluation } from './components/CustomiseCandidateSubmissionEvaluation';
import { CustomiseCandidateSubmissionProfile } from './components/CustomiseCandidateSubmissionProfile';
import { CustomiseCandidateSubmissionSendEmail } from './components/CustomiseCandidateSubmissionSendEmail';

interface ViewJobCandidateDetailsSubmissionSidebarProps {
  job: {
    id: JobId;
    nanoId: JobNanoId;
    jobTitle: JobJobTitle;
    name: JobName;
    cities: MayBeJobCities;
    city: MayBeJobCity;
    interviewers: JobInterviewers;
    hiringManager: MayBeJobHiringManager;
    company: MayBeJobCompany;
  };
  jobCandidateNanoId: JobCandidateNanoId;
}

function ViewJobCandidateDetailsSubmissionSidebar({
  job,
  jobCandidateNanoId
}: ViewJobCandidateDetailsSubmissionSidebarProps) {
  const { totalSteps, currentStep } =
    useViewJobCandidateDetailsSubmissionContext();
  const totalStepsArray = Array.from(Array(totalSteps).keys());

  const { jobCandidate } =
    useFetchJobCandidateByNanoId<FetchJobCandidateResponse>({
      jobCandidateNanoId: jobCandidateNanoId as JobCandidateNanoId,
      enabled: !!jobCandidateNanoId as FetchJobCandidateEnabled,
      cacheKey: JobCandidatesCache.showCacheKey(),
      query: fetchJobCandidateQuery
    });

  return (
    <Stack
      spacing={0}
      top="73px"
      right={0}
      w="400px"
      zIndex={1}
      pos="fixed"
      bg="white"
      flexDir="column"
      pointerEvents="none"
      h="calc(100vh - 137px)"
      borderLeft="1px solid"
      borderLeftColor="gray.200"
    >
      <Flex p={6} pb={2} gap={4}>
        {totalStepsArray.map((step) => (
          <Box
            h={1}
            w="full"
            key={step}
            borderRadius="full"
            bg={currentStep < step + 1 ? 'gray.300' : 'primary.500'}
          />
        ))}
      </Flex>

      <Flex
        px={6}
        pt={4}
        pb={6}
        mr={-6}
        gap={10}
        h="full"
        w="full"
        overflow="auto"
        flexDir="column"
        pointerEvents="all"
      >
        {currentStep === 1 && <CustomiseCandidateSubmissionProfile />}

        {currentStep === 2 && <CustomiseCandidateSubmissionAssessment />}

        {currentStep === 3 && <CustomiseCandidateSubmissionEvaluation />}

        {currentStep === 4 && jobCandidate && (
          <CustomiseCandidateSubmissionSendEmail
            job={job}
            jobCandidate={jobCandidate}
          />
        )}
      </Flex>
    </Stack>
  );
}

export default ViewJobCandidateDetailsSubmissionSidebar;
