import some from 'lodash/some';

import { CandidateSubmissionEvaluations } from '../../../../../candidateSubmissionsTypes';

const recipientIdsRules = {
  required: 'Profile recipients is required'
};

const overallEvaluationRules = {
  required: 'Overall evaluation is required'
};

const evaluationsRules = {
  required: 'Evaluations is required',
  validation: (value: CandidateSubmissionEvaluations) => {
    const rated = some(
      Object.values(value),
      (evaluation) => evaluation.rating || evaluation.comment
    );

    return rated || "Enter Candidate's evaluation";
  }
};

const commentRules = {
  required: 'Comment is required'
};

function useAddCandidateSubmissionFormValidationRules() {
  return {
    recipientIdsRules,
    commentRules,
    overallEvaluationRules,
    evaluationsRules
  };
}

export default useAddCandidateSubmissionFormValidationRules;
