import { JobNanoId } from '../jobs/jobsTypes';
import { PipelineRoutes } from '../pipeline/PipelineRoutes';

const basePath = PipelineRoutes.recruit();

export class RecruitRoutes {
  static index() {
    return `${basePath}`;
  }

  static viewRecruitDetails(jobNanoId: JobNanoId) {
    return `${basePath}/${jobNanoId}`;
  }
}
