import {
  FetchJobFunctionsCacheKey,
  FetchJobFunctionCacheKey
} from './jobFunctionsTypes';

import { JobCandidateId } from '../jobCandidates/jobCandidatesTypes';

import { JobResumeId } from '../jobResumes/jobResumesTypes';

import { JobResumeRecruiterId } from '../jobResumeRecruiters/jobResumeRecruitersTypes';

import { DisciplineId } from '../disciplines/disciplinesTypes';
import { CompanyScorecardId } from '../companyScorecards/companyScorecardsTypes';
import { JobScorecardId } from '../jobScorecards/jobScorecardsTypes';

export class JobFunctionsCache {
  static indexCacheKey() {
    return 'jobFunctions' as FetchJobFunctionsCacheKey;
  }

  static showCacheKey() {
    return 'jobFunction' as FetchJobFunctionCacheKey;
  }

  static disciplineIndexCacheKey(disciplineId: DisciplineId) {
    return `jobFunctions-discipline-${disciplineId}` as FetchJobFunctionsCacheKey;
  }

  static disciplineExistsCacheKey(disciplineId: DisciplineId) {
    return `jobFunctions-discipline-exists-${disciplineId}` as FetchJobFunctionsCacheKey;
  }

  static jobCandidateListCacheKey(jobCandidateId: JobCandidateId) {
    return `jobFunctions-${jobCandidateId}` as FetchJobFunctionsCacheKey;
  }

  static jobResumeListCacheKey(jobResumeId: JobResumeId) {
    return `jobFunctions-${jobResumeId}` as FetchJobFunctionsCacheKey;
  }

  static jobResumeRecruiterListCacheKey(
    jobResumeRecruiterId: JobResumeRecruiterId
  ) {
    return `jobFunctions-${jobResumeRecruiterId}` as FetchJobFunctionsCacheKey;
  }

  static companyScorecardIndexCacheKey(companyScorecardId: CompanyScorecardId) {
    return `jobFunctions-company-scorecard-${companyScorecardId}` as FetchJobFunctionsCacheKey;
  }

  static jobScorecardIndexCacheKey(jobScorecardId: JobScorecardId) {
    return `jobFunctions-job-scorecard-${jobScorecardId}` as FetchJobFunctionsCacheKey;
  }
}
