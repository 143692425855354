import { ButtonGroup, Flex, HStack } from '@chakra-ui/react';
import { Button } from '../../../../../../../../helpers/Button';
import { Text } from '../../../../../../../../helpers/Text';
import { IconButton } from '../../../../../../../../helpers/buttons/IconButton';
import { AlertIcon } from '../../../../../../../../icons/AlertIcon';
import { ChevronRightIcon } from '../../../../../../../../icons/ChevronRightIcon';
import { ClockIcon } from '../../../../../../../../icons/ClockIcon';
import { ThumbDownIcon } from '../../../../../../../../icons/ThumbDownIcon';
import {
  JobCandidateFirstName,
  JobCandidateName,
  JobCandidateNanoId
} from '../../../../../../../jobCandidates/jobCandidatesTypes';
import { JobCandidateDetailsPageWorkflowStage } from '../../../../ViewJobCandidateDetailsPage.types';
import { useViewJobCandidateDetailsSubmissionContext } from '../../../../contexts/ViewJobCandidateDetailsSubmissionContext';
import { useViewJobCandidateDetailsSendAssessmentContext } from '../../../../contexts/ViewJobCandidateDetailsSendAssessmentContext';

interface ViewJobCandidateDetailsTaskRequireAttentionprops {
  jobCandidate: {
    name: JobCandidateName;
    nanoId: JobCandidateNanoId;
    firstName?: JobCandidateFirstName;
    workflowStage: JobCandidateDetailsPageWorkflowStage;
  };
  skipTask: () => void;
  status?: 'overdue';
}

function ViewJobCandidateDetailsTaskRequireAttention({
  jobCandidate,
  skipTask,
  status
}: ViewJobCandidateDetailsTaskRequireAttentionprops) {
  const { closeSendEmailAssessment } =
    useViewJobCandidateDetailsSendAssessmentContext();

  const { openCandidateSubmission } =
    useViewJobCandidateDetailsSubmissionContext();

  const triggerOpenCandidateSubmission = () => {
    openCandidateSubmission();
    closeSendEmailAssessment();
  };

  return (
    <Flex
      border="1px solid"
      borderColor="gray.200"
      borderRadius="base"
      overflow="hidden"
    >
      <Flex
        p={5}
        w={40}
        gap={1}
        bg={status === 'overdue' ? 'red.600' : '#EEA401'}
        color="white"
        flexDir="column"
        justifyContent="center"
      >
        <Text textStyle="body1Medium" fontWeight="semibold" lineHeight={5}>
          {jobCandidate.workflowStage.name}
        </Text>

        <Flex h={6} alignItems="center">
          <HStack>
            <ClockIcon w={4} h={4} fill="white" />
            <Text textStyle="body2Medium">In stage 4 days</Text>
          </HStack>
        </Flex>
      </Flex>

      <Flex
        w={72}
        p={4}
        gap={1}
        bg={status === 'overdue' ? 'red.50' : '#FDF6E6'}
        flexDir="column"
        position="relative"
        justifyContent="center"
      >
        <HStack color={status === 'overdue' ? 'red.600' : '#EEA401'}>
          <AlertIcon w={4} h={4} />

          <Text
            textStyle="body1Medium"
            fontWeight="semibold"
            lineHeight={5}
            color={status === 'overdue' ? 'red.600' : '#EEA401'}
          >
            Task Requires Attention
          </Text>
        </HStack>

        <ButtonGroup spacing={2}>
          <Button
            size="medium"
            hierarchy="link"
            fontWeight="medium"
            lineHeight={6}
            alignItems="center"
            onClick={triggerOpenCandidateSubmission}
          >
            <Text as="span">Submit candidate</Text>
            <ChevronRightIcon w={4} h={4} />
          </Button>

          <Text>or</Text>

          <Button
            size="medium"
            color="gray.600"
            hierarchy="link"
            onClick={skipTask}
            fontWeight="medium"
            lineHeight={6}
          >
            Skip Task
          </Button>
        </ButtonGroup>
      </Flex>

      <Flex w={16} alignItems="center" justifyContent="center">
        <IconButton
          h={9}
          w={9}
          pb="9px"
          pt="11px"
          bg="red.100"
          minW="unset"
          border="none"
          hierarchy="outline"
          borderRadius="100%"
          aria-label="thumb down"
        >
          <ThumbDownIcon color="red.500" w={4} h={4} />
        </IconButton>
      </Flex>
    </Flex>
  );
}

export default ViewJobCandidateDetailsTaskRequireAttention;
