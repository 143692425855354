import { FieldValues, useController } from 'react-hook-form';

import {
  BaseSalaryInputFormFieldReactHookFormControl,
  BaseSalaryInputFormFieldReactHookFormFieldPath,
  BaseSalaryInputFormFieldReactHookFormRules
} from './BaseSalaryInputFormField.types';

import { BaseSalaryInputFormFieldControl } from './components/BaseSalaryInputFormFieldControl';

export interface BaseSalaryInputFormFieldProps<T extends FieldValues> {
  control: BaseSalaryInputFormFieldReactHookFormControl<T>;
  name: BaseSalaryInputFormFieldReactHookFormFieldPath<T>;
  label?: string;
  placeholder?: string;
  isDisabled?: boolean;
  isRequired?: boolean;
  errorMessage?: string | null;
  rules?: BaseSalaryInputFormFieldReactHookFormRules<T>;
}

function BaseSalaryInputFormField<T extends FieldValues>({
  label,
  placeholder,
  name,
  control,
  isDisabled,
  isRequired,
  errorMessage,
  rules
}: BaseSalaryInputFormFieldProps<T>) {
  const { field } = useController({
    name,
    control,
    rules
  });

  return (
    <BaseSalaryInputFormFieldControl
      label={label}
      placeholder={placeholder}
      ref={field.ref}
      onChange={field.onChange}
      value={field.value}
      isDisabled={isDisabled}
      isRequired={isRequired}
      name={name}
      errorMessage={errorMessage}
    />
  );
}

export default BaseSalaryInputFormField;
