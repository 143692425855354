import { Stack } from '@chakra-ui/react';
import { useState } from 'react';
import { Heading } from '../../../../../../../../helpers/Heading';
import { CustomiseCandidateSubmissionEvaluationItem } from '../CustomiseCandidateSubmissionEvaluationItem';
import { CustomiseCandidateSubmissionEvaluationVisibility } from '../CustomiseCandidateSubmissionEvaluationVisibility';
import CustomiseCandidateSubmissionEvaluationOverall from '../CustomiseCandidateSubmissionEvaluationOverall/CustomiseCandidateSubmissionEvaluationOverall';
import { useViewJobCandidateDetailsSubmissionContext } from '../../../../contexts/ViewJobCandidateDetailsSubmissionContext';
import { CandidateSubmissionEvaluationsEnum } from '../../../../../../../candidateSubmissions/candidateSubmissionsTypes';
import { Text } from '../../../../../../../../helpers/Text';

export default function CustomiseCandidateSubmissionEvaluation() {
  const [openIndex, setOpenIndex] = useState<number | null>(null);
  const { evaluations, validationErrors } =
    useViewJobCandidateDetailsSubmissionContext();

  const handleToggle = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const evaluationsArr = Object.keys(
    evaluations
  ) as CandidateSubmissionEvaluationsEnum[];

  return (
    <Stack spacing={5}>
      <Heading level="h5" textStyle="body1Medium" fontWeight="semibold">
        Candidate evaluation
      </Heading>

      <Stack spacing={2}>
        <Stack spacing={3}>
          {evaluationsArr?.map((evaluation, index) => (
            <CustomiseCandidateSubmissionEvaluationItem
              key={evaluation}
              label={evaluation}
              index={0}
              isOpen={openIndex === index}
              onToggle={() => handleToggle(index)}
            />
          ))}
        </Stack>
        {validationErrors.evaluationsValidationError ? (
          <Text color="red.500" textStyle="body2Medium" wordBreak="break-all">
            {validationErrors.evaluationsValidationError}
          </Text>
        ) : null}
      </Stack>

      <CustomiseCandidateSubmissionEvaluationOverall />

      <CustomiseCandidateSubmissionEvaluationVisibility />
    </Stack>
  );
}
